import {createSlice} from "@reduxjs/toolkit";

const withdrawalApplicationSlice = createSlice({
    name: "withdrawalApplicationSlice",
    initialState: {
        withdrawalApplicationData: '',
    },
    reducers: {
        setWithdrawalApplicationData(state, action) {
            state.withdrawalApplicationData = action?.payload
        }

    }
})

export default withdrawalApplicationSlice.reducer
export const {setWithdrawalApplicationData} = withdrawalApplicationSlice.actions
