import {createSlice} from "@reduxjs/toolkit";

const confirmEmailSlice = createSlice({
    name: "confirmEmailSlice",
    initialState: {
        confirmEmailData: '',
    },
    reducers: {
        setConfirmEmailData(state, action) {
            state.confirmEmailData = action?.payload
        },

    }
})

export default confirmEmailSlice.reducer
export const {setConfirmEmailData} = confirmEmailSlice.actions
