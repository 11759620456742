import {useEffect, useState} from "react";

export const useValidation = (value, validations) => {

    const [isEmpty, setIsEmpty] = useState(true);
    const [minLengthError, setMinLengthError] = useState(false);
    const [maxLengthError, setMaxLengthError] = useState(false);
    const [userNameError, setUserNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [inputValid, setInputValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        for (const validation in validations) {
            switch (validation) {
                case 'isEmpty':
                    if(value) {
                        setIsEmpty(false)
                        setErrorMessage('')
                    } else {
                        setIsEmpty(true)
                    }
                    break;

                case 'isEmail':
                    const regularExpEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,40}))$/
                    if(value && (regularExpEmail.test(String(value).toLowerCase()))) {
                        setEmailError(false)
                        setErrorMessage("")
                    } else {
                        setEmailError(true)
                        if(value) {
                            setErrorMessage("Adres e-mail jest nieprawidłowy.")
                        }
                    }
                    break

                //// old version
                // case 'isPhone':
                //     let checkStartDigits = value.startsWith("48")
                //     let regularExpPhone = /^\d+$/
                //     let checkRemainingDigits = regularExpPhone.test(String(value.slice(3)));
                //
                //     if(checkStartDigits && checkRemainingDigits) {
                //        setPhoneError(false)
                //        setErrorMessage("")
                //     } else {
                //         setPhoneError(true)
                //         if(value) {
                //             return setErrorMessage(`Numer telefonu musi zaczynać się od "48".`)
                //         }
                //     }
                //     break;

                //// new version
                case 'isPhone':
                    let regularExpPhone = /^\+?48\d+$/;
                    let isValidPhone = regularExpPhone.test(value);

                    if (isValidPhone) {
                        setPhoneError(false);
                        setErrorMessage("");
                    } else {
                        setPhoneError(true);
                        if (value) {
                            return setErrorMessage(`Numer telefonu musi zaczynać się od "48" lub "+48".`);
                        }
                    }
                    break;

                // // old version
                // case 'minLength':
                //     if(value && (value.length < validations[validation])) {
                //         setMinLengthError(true)
                //         setErrorMessage(`Nie mniej niż ${validations[validation]} znaków.`)
                //     } else {
                //         setMinLengthError(false)
                //         setErrorMessage("")
                //     }
                //     break;

                //new version
                case 'minLength':
                    // Remove '+' symbol if present
                    const clearedValue = value.startsWith('+') ? value.slice(1) : value;

                    if (clearedValue && (clearedValue.length < validations[validation])) {
                        setMinLengthError(true);
                        setErrorMessage(`Nie mniej niż ${validations[validation]} znaków.`);
                    } else {
                        setMinLengthError(false);
                        setErrorMessage("");
                    }
                    break;

                // // old version
                // case 'maxLength':
                //     if(value && (value.length > validations[validation])) {
                //         // console.log('maxLength if')
                //         setMaxLengthError(true)
                //         setErrorMessage(`Nie więcej niż ${validations[validation]} znaków`)
                //     } else {
                //         setMaxLengthError(false)
                //         if(!minLengthError) {
                //             setErrorMessage("")
                //         }
                //     }
                //     break;

                //new version
                case 'maxLength':
                    // Remove '+' symbol if present
                    const normalizedValue = value.startsWith('+') ? value.slice(1) : value;

                    if (normalizedValue && (normalizedValue.length > validations[validation])) {
                        setMaxLengthError(true);
                        setErrorMessage(`Nie więcej niż ${validations[validation]} znaków`);
                    } else {
                        setMaxLengthError(false);
                        if (!minLengthError) {
                            setErrorMessage("");
                        }
                    }
                    break;

                case 'isPassword':
                    const regularExpPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?()+=_-])[A-Za-z\d#$@!%&*?()+=_-]{6,16}$/
                    if(regularExpPassword.test(String(value))) {
                        setPasswordError(false)
                        setErrorMessage("")
                    } else {
                        setPasswordError(true)
                        if(value) {
                            setErrorMessage("Password is not valid.")
                        }
                    }
                    break;
            }
        }
    },[value])

    useEffect(() => {
        if(isEmpty || maxLengthError || minLengthError || emailError || passwordError || userNameError || phoneError) {
            setInputValid(false)
        } else {
            setInputValid(true)
        }
    }, [isEmpty, maxLengthError, minLengthError, emailError, passwordError, userNameError, phoneError])

    return {
        isEmpty,
        minLengthError,
        emailError,
        maxLengthError,
        passwordError,
        inputValid,
        errorMessage,
        userNameError,
        phoneError
    }
}


