import  "./LoginPage.scss"
import {useEffect, useState} from "react";
import {useInput} from "../../hooks/useInput";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {PageTitle} from "../../components/UiLibraryComponents/PageTitle/PageTitle";
import {InputLocal} from "../../components/UiLibraryComponents/Inputs/InputLocal/InputLocal";
import {ButtonBig} from "../../components/UiLibraryComponents/Buttons/ButtonBig/ButtonBig";
import {PhoneConfirm} from "../../components/phoneConfirm/phoneConfirm";
import {SimpleTextInfo} from "../../components/UiLibraryComponents/SimpleTextInfo/SimpleTextInfo";
import {SimpleLink} from "../../components/UiLibraryComponents/SimpleLink/SimpleLink";
import {signUpPageLink, userPageLink} from "../../constants/linksConstants/linksConstants";
import {loginThunk} from "../../redux/loginSlice/login.thunk";
import {useAuth} from "../../hooks/useAuth";
import {setLoginData} from "../../redux/loginSlice/login.slice";
import {useDisableButton} from "../../hooks/useDisableButton";
import {LoadingRing} from "../../components/UiLibraryComponents/LoadingRing/LoadingRing";
import {normalizePhoneNumber} from "../../helpers/normalizePhoneNumber";


export const LoginPage = () => {

// states and variables
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const loginData = useSelector(state => state.loginSlice.loginData)
    const userPhone = useInput('', {isEmpty: true, isPhone: true, minLength: 11, maxLength: 11})
    const [loginSteps, setLoginSteps] = useState({stepOne: false,})
    const disableLoginButton = useDisableButton()

    // auth check
    const {isAuth, token,} = useAuth()

    //error state
    const [loginError, setLoginError] = useState(false)
// states and variables

// send login request
    const handleLogin = async (e) => {
        e.preventDefault();

        // Clear error
        setLoginError(false);

        // Disable button
        disableLoginButton.setButtonDisableState(true);

        // Remove '+' symbol from phone number if present
        const phoneNumber = normalizePhoneNumber(userPhone.value);

        await dispatch(loginThunk(dispatch, phoneNumber, setLoginError));

        // Enable button
        disableLoginButton.setButtonDisableState(false);
    };
// send login request

// set next step
    useEffect(() =>{
        if(loginData.success) {
            setLoginSteps({...loginSteps, stepOne: true})
        }
    }, [loginData,])
// set next step

// step back
    const stepBackToLoginForm = () => {
        setLoginSteps({stepOne: false})
        dispatch(setLoginData(''))
    }
// step back

// redirect to user page when log in
    const redirectToUserPage = async () => {
        await setLoginSteps({stepOne: false})
        await dispatch(setLoginData(''))
        navigate(`/${userPageLink}`)
    }
// redirect to user page when log in

// redirect to user page if authorized user visit login page
    useEffect(() => {
        if(isAuth) {
            navigate(`/${userPageLink}`)
        }
    }, [isAuth])
// redirect to user page if authorized user visit login page


    return (
        <div className={`loginPage`}>
            <div
                className={`loginPage__form-wrapper container`}
            >
                {
                    !loginSteps.stepOne &&
                    <>
                        <div className={`loginPage__top`}>
                            <img src="White-logo-novapartner.svg" alt=""/>
                            <PageTitle titleText={"Autoryzacja"}/>
                        </div>
                        <div className={`loginPage__form-wrapper-inner`}>
                            <form action="">
                                <div className={`loginPage__form-inputs-wrapper`}>
                                    <div className={`loginPage__form-input-wrapper`}>
                                        <InputLocal
                                            type="tel"
                                            value={userPhone.value}
                                            inputClassList={"input-local input-login margin-top-18"}
                                            placeholder={'48XXXXXXXXX'}
                                            onChange={(e) => userPhone.onChange(e)}
                                            onBlur={(e) => userPhone.onBlur(e)}
                                            error={userPhone.errorMessage}
                                        />
                                    </div>
                                </div>
                                <div className={`loginPage__form-buttons-wrapper`}>
                                    {
                                        loginError && loginError?.message &&
                                        <span className={`loginPage__form-bottom-error`}>{loginError.message}</span>
                                    }
                                    {
                                        <
                                            ButtonBig
                                                buttonText={disableLoginButton.buttonDisableState ? <LoadingRing/> : 'Dalej'}
                                                buttonAction={handleLogin}
                                                isButtonDisabledArray={
                                                    [
                                                        userPhone.inputValid,
                                                        !disableLoginButton.buttonDisableState
                                                    ]
                                                }
                                        />
                                    }
                                    <div className={`loginPage__form-buttons-info`}>
                                        <div className={`loginPage__form-buttons-info-item`}>
                                            <SimpleTextInfo simpleTextInfo={"Nie masz jeszcze konta?"} />
                                            <SimpleLink  navigatePath={signUpPageLink} linkText={"Zarejestruj się"}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
                }
                {
                    loginSteps.stepOne && !loginSteps.stepTwo &&
                    <PhoneConfirm
                        userPhone={userPhone.value}
                        type={'login'}
                        stepBack={stepBackToLoginForm}
                        redirect={redirectToUserPage}
                    />
                }
            </div>
        </div>
    )
}