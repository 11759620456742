import {createSlice} from "@reduxjs/toolkit";

const withdrawalsArchiveSlice = createSlice({
    name: "withdrawalsArchiveSlice",
    initialState: {
        withdrawalsArchiveData: '',
    },
    reducers: {
        setUserWithdrawalsArchiveData(state, action) {
            state.withdrawalsArchiveData = action?.payload
        },

    }
})

export default withdrawalsArchiveSlice.reducer
export const {setUserWithdrawalsArchiveData} = withdrawalsArchiveSlice.actions
