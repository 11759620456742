import {createSlice} from "@reduxjs/toolkit";

const phoneConfirmSlice = createSlice({
    name: "phoneConfirmSlice",
    initialState: {
        phoneConfirmSignUpData: '',
        phoneConfirmLoginData: '',
    },
    reducers: {
        setPhoneConfirmSignUpData(state, action) {
            state.phoneConfirmSignUpData = action?.payload
        },
        setPhoneConfirmLoginData(state, action) {
            state.phoneConfirmLoginData = action?.payload
        },

    }
})

export default phoneConfirmSlice.reducer
export const {setPhoneConfirmSignUpData, setPhoneConfirmLoginData} = phoneConfirmSlice.actions
