import {createSlice} from "@reduxjs/toolkit";

const confirmEmailByLinkSlice = createSlice({
    name: "confirmEmailByLinkSlice",
    initialState: {
        confirmEmailByLinkData: '',
    },
    reducers: {
        setConfirmEmailByLinkData(state, action) {
            state.confirmEmailByLinkData = action?.payload
        },

    }
})

export default confirmEmailByLinkSlice.reducer
export const {setConfirmEmailByLinkData} = confirmEmailByLinkSlice.actions
