import {createSlice} from "@reduxjs/toolkit";

const facturesSlice = createSlice({
    name: "facturesSlice",
    initialState: {
        facturesData: '',
    },
    reducers: {
        setFacturesData(state, action) {
            state.facturesData = action?.payload
        },

    }
})

export default facturesSlice.reducer
export const {setFacturesData} = facturesSlice.actions
