import {createSlice} from "@reduxjs/toolkit";

const commissionSlice = createSlice({
    name: "commissionSlice",
    initialState: {
        commissionData: '',
    },
    reducers: {
        setCommissionData(state, action) {
            state.commissionData = action?.payload
        },

    }
})

export default commissionSlice.reducer
export const {setCommissionData} = commissionSlice.actions
