import "./SuccessMessagePage.scss"
import {useNavigate} from "react-router-dom";
import {PageTitle} from "../../components/UiLibraryComponents/PageTitle/PageTitle";
import successMarkIcon from "../../assets/icons/Successmark.svg";
import backArrow from "../../assets/icons/back_arrow.svg"
import React, {useEffect, useState} from "react";
import {loginPageLink, userPageLink} from "../../constants/linksConstants/linksConstants";
import {useAuth} from "../../hooks/useAuth";
import {SimpleLink} from "../../components/UiLibraryComponents/SimpleLink/SimpleLink";
import {useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {confirmEmailByLinkThunk} from "../../redux/confirmEmailByLink/confirmEmailByLink.thunk";
import {LoadingRing} from "../../components/UiLibraryComponents/LoadingRing/LoadingRing";

export const SuccessMessagePage = () => {

// states and variables
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {isAuth} = useAuth();
    const location = useLocation();
    const confirmEmailByLinkData = useSelector(state => state.confirmEmailByLinkSlice.confirmEmailByLinkData)

    // error state
    const [confirmEmailByLinkDataError, setConfirmEmailByLinkDataError] = useState(false)
// end states and variables
    const redirectToUserPage = () => {
        navigate(`/${userPageLink}`)
    }

// get email from link
    const getEmailFromQuery = async () => {
        let searchParamsEmail = new URLSearchParams(location.search).get('email');
        await dispatch(confirmEmailByLinkThunk(dispatch, searchParamsEmail, setConfirmEmailByLinkDataError))
    };

    useEffect(() => {
        getEmailFromQuery();
    }, [location.search]);
// end get email from link


    return (
        <div className={`successMessagePage container`}>
            {
                isAuth &&
                <>
                    <div
                        className={`successMessagePage__back-button`}
                        onClick={redirectToUserPage}
                    >
                        <img src={backArrow} alt="Back"/>
                    </div>

                    <div>
                        {
                            !confirmEmailByLinkData && !confirmEmailByLinkDataError &&
                            <LoadingRing/>
                        }
                        {
                            confirmEmailByLinkData &&
                            confirmEmailByLinkData.success &&
                            <>
                                <img src={successMarkIcon} alt="success icon"/>
                                <PageTitle titleText={`Twój adres e-mail został pomyślnie potwierdzony`} />
                            </>
                        }
                        {
                            confirmEmailByLinkDataError && confirmEmailByLinkDataError.message &&
                            <PageTitle titleText={`${confirmEmailByLinkDataError.message}`} />
                        }
                    </div>
                </>
            }
            {
                !isAuth &&
                <SimpleLink
                    navigatePath={loginPageLink}
                    linkText={"Zaloguj się"}
                />
            }
        </div>
    )
}