import {setCommissionData} from "./commission.slice";
import {apiErrorInterceptor} from "../../interceptors/apiErrorInterceptor";
import {apiRequestService} from "../../services/apiRequestService";

export const commissionThunk = (dispatch, setError) => {

    return async (dispatch) => {
        try {

            const apiEndPoint = '/withdrawal/check-this-week-withdrawal'

            const response =
                await apiRequestService(apiEndPoint, 'GET')

            const data = await response.json();

            if (response.ok) {
                dispatch(setCommissionData(data.user))
            }
            else {
                setError(data)
            }

        } catch (error) {
            apiErrorInterceptor(error);
        }
    };
};


