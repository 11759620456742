import "./UserWithdrawals.scss"
import {ButtonBig} from "../../../../components/UiLibraryComponents/Buttons/ButtonBig/ButtonBig";
import moneyTransfer from "../../../../assets/icons/money-bill-transfer-solid.svg"
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../../../../hooks/useAuth";
import {useEffect, useState} from "react";
import {userWithdrawalsDataThunk} from "../../../../redux/userWithdrawalsDataSlice/userWithdrawalsData.thunk";
import withdrawIcon from "../../../../assets/icons/withdraw-icon.png"
import LoadingSkeletonList from "../../../../components/UiLibraryComponents/LoadingSkeletonList/LoadingSkeletonList";

export const UserWithdrawals = ({handleComponentChange}) => {
// states and variables
    const userTransactionsData = useSelector(state => state.userTransactionsSlice.userTransactionsData)
    const userWithdrawalsData = useSelector(state => state?.userWithdrawalsDataSlice?.userWithdrawalsData?.data)
    const { balance, transactions } = userTransactionsData;
    const dispatch = useDispatch()
    const {token} = useAuth()

    //error states
    const [noWithdrawalsDataError, setNoWithdrawalsDataError] = useState(false)
// end states and variables

// get User Withdrawal Data
    const getUserWithdrawalsData = async () => {
        await dispatch(userWithdrawalsDataThunk(dispatch, 1, setNoWithdrawalsDataError))
    }
    useEffect(() => {
        if(token) {
            getUserWithdrawalsData()
        }
    },[token])
// end get User Withdrawal Data

    return (
        <div className={`userWithdrawals`}>
            <div className={`userWithdrawals__main-balance`}>
                <div className={`userWithdrawals__main-balance-inner`}>
                    <span>Twój stan konta</span>
                    <p>{balance} zł</p>
                </div>
                <div className={`userWithdrawals__balance-icon-wrapper`}>
                    <img src={withdrawIcon} alt="icon"/>
                </div>
            </div>
            <div className={`userWithdrawals__application`}>
                <h2 className={`userWithdrawals__application-title`}>Zamów wypłatę natychmiastową</h2>
                <ButtonBig
                    buttonText={`Zamów wypłatę`}
                    buttonAction={() => handleComponentChange('WithdrawalApplication')}
                    isButtonDisabledArray={[true]}
                />
            </div>
            <div className="userWithdrawals__transaction-list-wrapper">
                <div className="userWithdrawals__list-title-wrapper">
                    <h3 className="userWithdrawals__list-title">Zlecenia wypłat</h3>
                    <span onClick={() => handleComponentChange('WithdrawalsArchive')}>Archiwum</span>
                </div>
                {
                    !noWithdrawalsDataError && !noWithdrawalsDataError.message &&
                    !userWithdrawalsData &&
                    <LoadingSkeletonList/>
                }
                {
                    noWithdrawalsDataError && noWithdrawalsDataError.message &&
                    <p className={`userWithdrawals__no-data`}>{noWithdrawalsDataError.message}</p>

                }
                <ul className="userWithdrawals__transaction-list">
                    {
                        userWithdrawalsData &&
                        userWithdrawalsData.map((item, index) => {

                            // const formattedDate = new Date(item.last_date_withdrawal_update).toLocaleDateString('ru-RU').split('.').join('.');
                            const uniqueKey = `transaction-${index}`;

                            return (
                                <li key={uniqueKey} className="userWithdrawals__transaction-list-item">
                                    <div className="userWithdrawals__transaction-list-item-left">
                                        <div className="userWithdrawals__transaction-list-item-icon-wrapper">
                                            <img src={moneyTransfer} alt="icon"/>
                                        </div>
                                        <div className="userWithdrawals__transaction-list-item-left-text">
                                            <p>{item?.withdrawal_amount} zł</p>
                                            <span>{item.last_date_withdrawal_update}</span>
                                        </div>
                                    </div>
                                    <p className="userWithdrawals__transaction-list-item-amount">
                                        {item.status === "Sent" &&  'Wysłano'}
                                        {item.status === "Paid" &&  'Wypłacone'}
                                    </p>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}