import React from "react"
import ContentLoader from "react-content-loader"
import "./LoadingSkeletonList.scss"

const LoadingSkeletonList = (props) => {

    const numberOfSkeletonItems = 3;

    return (
        <div className={`loadingSkeletonList`}>
            <div className={`loadingSkeletonList`}>
                {
                    Array.from({length: numberOfSkeletonItems}, (item, index) => {
                        return (
                            <div key={index} className={`loadingSkeletonList-item`}>
                                <ContentLoader
                                    speed={2}
                                    width={310}
                                    height={40}
                                    viewBox="0 0 330 40"
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                    {...props}
                                >
                                    <rect x="48" y="8" rx="3" ry="3" width="270" height="6"/>
                                    <rect x="48" y="26" rx="3" ry="3" width="270" height="6"/>
                                    <circle cx="15" cy="20" r="15"/>
                                </ContentLoader>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default LoadingSkeletonList

