import {setPhoneConfirmSignUpData, setPhoneConfirmLoginData} from "../../redux/phoneConfirmSlice/phoneConfirm.slice"
import {apiRequestService} from "../../services/apiRequestService";
import {apiErrorInterceptor} from "../../interceptors/apiErrorInterceptor";

export const phoneConfirmThunk = (dispatch, userPhone, phoneCode, type, setError) => {

    return async (dispatch) => {
        try {
            const apiEndPoint = `/auth/phone-confirm`

            const requestBody = new URLSearchParams();
            requestBody.append("code", `${phoneCode}`);
            requestBody.append("phone", `${userPhone}`);

            const response =
                await apiRequestService(apiEndPoint, 'POST', requestBody)

            const data = await response.json();

            if (response.ok) {
                if(type === 'signUp') {
                    dispatch(setPhoneConfirmSignUpData(data))
                    dispatch(setPhoneConfirmLoginData(data))
                }
                if(type === 'login') {
                    dispatch(setPhoneConfirmLoginData(data))
                }
            }
            else {
                setError(data)
            }

        } catch (error) {
            apiErrorInterceptor(error);
        }
    };
};


