import {createSlice} from "@reduxjs/toolkit";


const unreadNotificationsSlice = createSlice({
    name: "unreadNotificationsSlice",
    initialState: {
        unreadNotificationsData: '',
    },
    reducers: {
        setUnreadNotificationsData(state, action) {
            state.unreadNotificationsData = action?.payload
        },
    }
})

export default unreadNotificationsSlice.reducer
export const {setUnreadNotificationsData} = unreadNotificationsSlice.actions
