import {setFacturesArchiveData} from "./facturesArchive.slice";
import {apiErrorInterceptor} from "../../interceptors/apiErrorInterceptor";
import {apiRequestService} from "../../services/apiRequestService";

export const facturesArchiveThunk = (dispatch, page, setError) => {

    return async (dispatch) => {
        try {
            const apiEndPoint = `/facture?page=${page}`

            const response =
                await apiRequestService(apiEndPoint, 'GET')

            const data = await response.json();

            if (response.ok) {
                dispatch(setFacturesArchiveData(data))
            }
            else {
                setError(data)
            }

        } catch (error) {
            apiErrorInterceptor(error);
        }
    };
};


