
export function createRequestOptions(method, requestHeaders, requestBody) {

    const requestOptions = {
        method: `${method}`,
        headers: requestHeaders,
        redirect: 'follow'
    };

    if(requestBody) {
        requestOptions.body = requestBody
    }

    return requestOptions
}