import  "./SignUpPage.scss"
import {useEffect, useState} from "react";
import {useInput} from "../../hooks/useInput";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {PageTitle} from "../../components/UiLibraryComponents/PageTitle/PageTitle";
import {InputLocal} from "../../components/UiLibraryComponents/Inputs/InputLocal/InputLocal";
import {ButtonBig} from "../../components/UiLibraryComponents/Buttons/ButtonBig/ButtonBig";
import {PhoneConfirm} from "../../components/phoneConfirm/phoneConfirm"
import {signUpThunk} from "../../redux/signUpSlice/signUp.thunk"
import {SimpleTextInfo} from "../../components/UiLibraryComponents/SimpleTextInfo/SimpleTextInfo";
import {SimpleLink} from "../../components/UiLibraryComponents/SimpleLink/SimpleLink";
import {loginPageLink, userPageLink} from "../../constants/linksConstants/linksConstants";
import successIcon from "../../assets/icons/Successmark.svg"
import {setPhoneConfirmSignUpData} from "../../redux/phoneConfirmSlice/phoneConfirm.slice";
import {setNewUserData} from "../../redux/signUpSlice/signUp.slice";
import {useDisableButton} from "../../hooks/useDisableButton";
import {LoadingRing} from "../../components/UiLibraryComponents/LoadingRing/LoadingRing";
import {normalizePhoneNumber} from "../../helpers/normalizePhoneNumber";


export const SignUpPage = () => {

// states end variables
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const signUpData = useSelector(state => state.signUpSlice.signUpData)
    const phoneConfirmSignUpData = useSelector(state => state.phoneConfirmSlice.phoneConfirmSignUpData)
    const loginData = useSelector(state => state?.loginSlice?.loginData)
    const phoneConfirmLoginData = useSelector(state => state.phoneConfirmSlice.phoneConfirmLoginData)
    const userName = useInput('', {isEmpty: true, minLength: 5, maxLength: 40, isUserName: true})
    const userPhone = useInput('', {isEmpty: true, isPhone: true, minLength: 11, maxLength: 11, })
    const email = useInput('', {isEmpty: true, isEmail: true})
    const [signUpSteps, setSignUpSteps] = useState(
        {stepOne: false, stepTwo: false})
    const disableSignUpButton = useDisableButton()

    const [signUpError, setSignUpError] = useState(false)
// end  states end variables

// save sign up data to local storage and go to next step
    useEffect(() =>{

        if(signUpData.success) {
            localStorage.setItem('signUpData', JSON.stringify(signUpData))
            setSignUpSteps({...signUpSteps, stepOne: true})
        }
        if(phoneConfirmSignUpData.success) {
            setSignUpSteps({...signUpSteps, stepTwo: true})
        }
    }, [signUpData, phoneConfirmSignUpData])
// save sign up data to local storage and go to next step


// step back
    const stepBackToSinUpForm = () => {
        setSignUpSteps({stepOne: false, stepTwo: false})
    }
// step back

// send Sign Up request
    const  handleClickSignUp = async (e) => {
        e.preventDefault()

        // clear error
        setSignUpError(false)

        //disable button
        disableSignUpButton.setButtonDisableState(true)

        // Remove '+' symbol from phone number if present
        const phoneNumber = normalizePhoneNumber(userPhone.value);

        await dispatch(signUpThunk(dispatch, userName.value, phoneNumber, email.value, setSignUpError))

        //enable button
        disableSignUpButton.setButtonDisableState(false)
    }
// End send Sign Up request

// redirect user to user page if sign up was successful
// clear data when redirect to user page
    const clearSignUpDataAndRedirectToUserPage = () => {
        setSignUpSteps({stepOne: false, stepTwo: false});
        dispatch(setNewUserData(''))
        dispatch(setPhoneConfirmSignUpData(''))
        navigate(`/${userPageLink}`)
    };
// clear data when redirect to user page


    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (phoneConfirmLoginData.token && phoneConfirmLoginData.success) {
                clearSignUpDataAndRedirectToUserPage()
            }
        }, 2000);
        return () => clearTimeout(timeoutId);
    }, [loginData, phoneConfirmLoginData])
// redirect user to user page if sign up was successful

// clear data when redirect to user page without sign up
    const clearSignUpDataAndRedirectToLoginPage = () => {
        setSignUpSteps({stepOne: false, stepTwo: false});
        dispatch(setNewUserData(''))
        dispatch(setPhoneConfirmSignUpData(''))
        navigate(`/${loginPageLink}`)
    };
// clear data when redirect to user page without sign up

    return (
        <div className={`signUpPage`}>
            <div className={`signUpPage__form-wrapper container`}>
                {
                    !signUpSteps.stepOne &&
                    <>
                        <div className={`signUpPage__top`}>
                            <img src="White-logo-novapartner.svg" alt=""/>
                            <PageTitle titleText={"Rejestracja"}/>
                        </div>
                        <form className={`signUpPage__form`}>
                            <div className={`signUpPage__form-inputs-wrapper`}>
                                <InputLocal
                                    type="text"
                                    value={userName.value}
                                    inputClassList={"input-local input-login margin-top-18"}
                                    placeholder={'Imię i nazwisko'}
                                    onChange={(e) => userName.onChange(e)}
                                    onBlur={(e) => userName.onBlur(e)}
                                    error={userName.errorMessage}
                                />
                                <InputLocal
                                    type="tel"
                                    value={userPhone.value}
                                    inputClassList={"input-local input-login margin-top"}
                                    placeholder={'48XXXXXXXXX'}
                                    onChange={(e) => userPhone.onChange(e)}
                                    onBlur={(e) => userPhone.onBlur(e)}
                                    error={userPhone.errorMessage  }
                                />
                                <InputLocal
                                    type="email"
                                    value={email?.value}
                                    inputClassList={"input-local input-login margin-top"}
                                    placeholder={'Email'}
                                    onChange={(e) => email.onChange(e)}
                                    onBlur={(e) => email.onBlur(e)}
                                    error={email.errorMessage}
                                />

                            </div>
                            <div className={`signUpPage__form-buttons-wrapper`}>
                                <div className={`signUpPage__form-bottom-error`}>
                                    {
                                        signUpError?.errors?.email &&
                                        <span>{signUpError?.errors?.email}</span>

                                    }
                                    {
                                        signUpError?.errors?.phone &&
                                        <p>{signUpError?.errors?.phone}</p>
                                    }
                                </div>
                                {
                                    <ButtonBig
                                        buttonText={disableSignUpButton.buttonDisableState ? <LoadingRing/> : 'Dalej'}
                                        buttonAction={handleClickSignUp}
                                        isButtonDisabledArray={
                                            [
                                                userName.inputValid,
                                                userPhone.inputValid,
                                                email.inputValid,
                                                !disableSignUpButton.buttonDisableState
                                            ]}
                                    />
                                }
                            </div>
                            <div className={`signUp__form-buttons-info`}>
                                <SimpleTextInfo simpleTextInfo={"Masz już konto?"} />
                                <SimpleLink
                                    // navigatePath={loginPageLink}
                                    linkText={"Zaloguj się"}
                                    onClickFunction={clearSignUpDataAndRedirectToLoginPage}
                                />
                            </div>
                        </form>
                    </>
                }
                {
                    signUpSteps.stepOne && !signUpSteps.stepTwo &&
                    <PhoneConfirm
                        userPhone={userPhone.value} type={'signUp'}
                        stepBack={stepBackToSinUpForm}
                    />
                }
                {
                    signUpSteps.stepTwo &&
                    <div className={`signUp__success`}>
                        <img src={successIcon} alt="success"/>
                        <PageTitle titleText={`Rejestracja przebiegła pomyślnie!`}/>
                        <SimpleTextInfo
                            simpleTextInfo={`"Dziękujemy. Nasz menedżer skontaktuje się z Tobą wkrótce.`}
                        />
                    </div>
                }
                <div className={`signUpPage__bottom`}>
                    <span>Kontynuując, akceptujesz warunki </span>
                    <a  href="https://nova-partner.pl/privacy-policy/" target="_blank">
                        Polityki prywatności
                    </a>

                </div>
            </div>
        </div>
    )
}



