import "./UserTransactions.scss"
import {useEffect, useState} from "react";
import {userTransactionsThunk} from "../../../../redux/userTransactions/userTransactions.thunk";
import {useDispatch, useSelector} from "react-redux";
import moneyIcon from "../../../../assets/icons/money-icon.svg"
import boltIcon from "../../../../assets/logos/bolt.png"
import freeNowIcon from "../../../../assets/logos/free-now.png"
import glovoIcon from "../../../../assets/logos/glovo.png"
import coinIcon from "../../../../assets/icons/coins-solid.svg"
import {useAuth} from "../../../../hooks/useAuth";
import moneySymbolIcon from "../../../../assets/icons/money-symbol-icon.png"
import greenMoneyIcon from "../../../../assets/icons/green-money-icon.png"
import LoadingSkeletonList from "../../../../components/UiLibraryComponents/LoadingSkeletonList/LoadingSkeletonList";

export const UserTransactions = () => {

// states and variables
    const dispatch = useDispatch()
    const userTransactionsData = useSelector(state => state.userTransactionsSlice.userTransactionsData)
    const { balance, transactions } = userTransactionsData;
    const {token} = useAuth()

    //error states
    const [noUserTransactionsDataError, setUserTransactionsDataError] = useState(false)
// states and variables

// get User Transactions Data
    const getUserTransactionsData = async () => {
        await dispatch(userTransactionsThunk(dispatch, setUserTransactionsDataError))
    }
    useEffect(() => {
        if(token) {
            getUserTransactionsData()
        }
    },[token])
// end get User Transactions Data

// render list item icon
    const renderListItemIcon = (iconName) => {
        if(iconName === "FreeNow") {
            return freeNowIcon
        }
        if(iconName === "Bolt") {
            return boltIcon
        }
        if(iconName === "Glovo") {
            return glovoIcon
        }
        else {
            return greenMoneyIcon
        }
    }
// render list item icon

    return (
        <div className={`userTransactions`}>
            <div className={`userTransactions__main-balance`}>
                <div className={`userTransactions__main-balance-inner`}>
                    <span>Twój stan konta</span>
                    <p>{balance} zł</p>
                </div>
                <div className={`userTransactions__balance-icon-wrapper`}>
                    <img src={moneySymbolIcon} alt="icon"/>
                </div>
            </div>
            <p className={`userTransactions__transaction-list-top-info`}>
                Kwota ma włączone wszystkie prowizji i podatki
            </p>
            <div className={`userTransactions__transaction-list-wrapper`}>
                <div className={`userTransactions__list-title-wrapper`}>
                    <h2 className={`userTransactions__list-title`}>Historia</h2>
                    <span>Pokaż całą historię</span>
                </div>
                {
                    !noUserTransactionsDataError && !noUserTransactionsDataError.message &&
                    !transactions &&
                    <LoadingSkeletonList/>
                }
                {
                    noUserTransactionsDataError && noUserTransactionsDataError.message &&
                    <p className={`userTransactions__no-data`}>
                        {noUserTransactionsDataError.message}
                    </p>
                }
                <ul className={`userTransactions__transaction-list`}>
                    {
                        transactions &&
                        Object.entries(transactions).map(([key, item], index) => {
                            return (
                                <li key={key} className={`userTransactions__transaction-list-item`}>
                                    <div className={`userTransactions__transaction-list-item-left`}>
                                        <span><img src={renderListItemIcon(item.name)} alt="icon"/></span>
                                        <div className={`userTransactions__transaction-list-item-left-text`}>
                                            <h3>{item.name}</h3>
                                            <span>
                                                {item.date}
                                            </span>
                                        </div>
                                    </div>
                                    <p className={`userTransactions__transaction-list-item-amount`}>
                                        {item.value} zł
                                    </p>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </div>
    )
}
