import {setNewUserData} from "../../redux/signUpSlice/signUp.slice"
import {apiRequestService} from "../../services/apiRequestService";

export const signUpThunk = (dispatch, userName, userPhone, email, setError) => {

    return async (dispatch) => {
        try {

            const apiEndPoint = `/auth/register`

            const requestBody = new URLSearchParams();
            requestBody.append("name", `${userName}`);
            requestBody.append("phone", `${userPhone}`);
            requestBody.append("email", `${email}`);

            const response =
                await apiRequestService(apiEndPoint, 'POST', requestBody)

            const data = await response.json();

            if(response.ok) {
                dispatch(setNewUserData(data))
            }
            else {
                setError(data)
            }

        } catch (error) {
            console.error('Error during signup:', error);
        }
    };
};


