import "./FacturesArchive.scss"
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../../../../hooks/useAuth";
import {facturesArchiveThunk} from "../../../../redux/facturesArchiveSlice/facturesArchive.thunk";
import {useEffect, useState} from "react";
import smallFactureIcon from "../../../../assets/icons/small-facture-icon.png"
import LoadingSkeletonList from "../../../../components/UiLibraryComponents/LoadingSkeletonList/LoadingSkeletonList";

export const FacturesArchive = () => {

    const facturesArchiveData = useSelector(state => state.facturesArchiveSlice.facturesArchiveData.data)
    const dispatch = useDispatch()
    const {token} = useAuth()

    //error states
    const [noFacturesArchiveDataError, setFacturesArchiveDataError] = useState(false)


// get Factures Archive Data
    const getFacturesArchiveData = async () => {
        await dispatch(facturesArchiveThunk(dispatch, 1, setFacturesArchiveDataError))
    }

    useEffect(() => {
        if(token) {
            getFacturesArchiveData()
        }
    },[token])
// end get Factures Archive Data


    return (
        <div className={`facturesArchive`}>
            <div className="facturesArchive__archive-list-wrapper">
                <div className="facturesArchive__archive-list-title-wrapper">
                    <h3 className="facturesArchive__archive-list-title">Faktury</h3>
                </div>
                {
                    !noFacturesArchiveDataError && !noFacturesArchiveDataError.message &&
                    !facturesArchiveData &&
                    <LoadingSkeletonList/>
                }
                {
                    noFacturesArchiveDataError && noFacturesArchiveDataError.message &&
                    <p className={`facturesArchive__no-data`}>{noFacturesArchiveDataError.message}</p>
                }
                <ul className="facturesArchive__archive-list">
                    {
                        facturesArchiveData &&
                        facturesArchiveData.map((item, index) => {

                            // const formattedDate = new Date(item.upload_date).toLocaleDateString('ru-RU').split('.').join('.');
                            const uniqueKey = `transaction-${index}`;

                            return (
                                <li
                                    key={uniqueKey}
                                    className="facturesArchive__archive-list-item"
                                >
                                    <div className="facturesArchive__archive-list-item-left">
                                        <img src={smallFactureIcon} alt="icon"/>
                                        <div className="facturesArchive__archive-list-item-left-text">
                                            <p>
                                                {item.name}
                                            </p>
                                            <span>
                                                {item.upload_date}
                                            </span>
                                        </div>
                                    </div>
                                    <p className={`facturesArchive__list-item-status ${item?.status ? item.status : ''}`}>
                                        {item.status === "Sent" &&  'Wysłane'}
                                        {item.status === "Paid" &&  'Wypłacone'}
                                        {item.status === "Accepted" &&  'Przyjęte'}
                                        {item.status === "Rejected" &&  'Odrzucone'}
                                    </p>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}