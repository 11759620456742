import "./UserProfile.scss"
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {userProfileThunk} from "../../../../redux/userProfileSlice/userProfile.thunk";
import {useInput} from "../../../../hooks/useInput";
import {InputLocal} from "../../../../components/UiLibraryComponents/Inputs/InputLocal/InputLocal";
import {ButtonBig} from "../../../../components/UiLibraryComponents/Buttons/ButtonBig/ButtonBig";
import {confirmEmailThunk} from "../../../../redux/confirmEmailSlice/confirmEmail.thunk";
import {updateUserProfileThunk} from "../../../../redux/updateUserProfileSlice/updateUserProfile.thunk";
import simpleChekMark from "../../../../assets/icons/simple-check-mark.svg"
import {SimpleTextInfo} from "../../../../components/UiLibraryComponents/SimpleTextInfo/SimpleTextInfo";
import backArrow from "../../../../assets/icons/back_arrow.svg"
import {setConfirmEmailData} from "../../../../redux/confirmEmailSlice/confirmEmail.slice";
import {useAuth} from "../../../../hooks/useAuth";
import closeIcon from "../../../../assets/icons/close-icon.svg";
import successIcon from "../../../../assets/icons/Successmark.svg";
import {PageTitle} from "../../../../components/UiLibraryComponents/PageTitle/PageTitle";
import {deleteAccountThunk} from "../../../../redux/deleteAccountSlice/deleteAccount.thunk";
import {useDisableButton} from "../../../../hooks/useDisableButton";
import {LoadingRing} from "../../../../components/UiLibraryComponents/LoadingRing/LoadingRing";


export const UserProfile = () => {

// states and variables
    const dispatch = useDispatch()
    const userProfileData = useSelector(state => state?.userProfileSlice.userProfileData)
    const deleteAccountData = useSelector(state => state?.deleteAccountSlice.deleteAccountData)
    const userEmailConfirmData = useSelector(state => state?.confirmEmailSlice.confirmEmailData)
    const userName = useInput('', {isEmpty: true, minLength: 5, maxLength: 40, isUserName: true})
    const email = useInput('', {isEmpty: true, isEmail: true})
    const [showEmailConfirmMessage, setShowEmailConfirmMessage] = useState(false)
    const [showDeleteAccountConfirmMessage, setDeleteAccountConfirmMessage] = useState(false)
    const [showDeleteAccountSuccessMessage, setShowDeleteAccountSuccessMessage] = useState(false)
    // const [disableEmailConfirmButton, setDisableEmailConfirmButton] = useState(false)
    const disableEmailConfirmButton = useDisableButton()
    const disableSaveUserProfileButton = useDisableButton()
    const disableDeleteAccountButton = useDisableButton()
    const {token, logOut} = useAuth()

    // error state
    const [noUserProfileDataError, setUserProfileDataError] = useState(false)

    const [userProfileUpdateError, setUserProfileUpdateError] = useState(false)
    const [confirmEmailError, setConfirmEmailError] = useState(false)
    const [deleteAccountError, setDeleteAccountError] = useState(false)
// end states and variables

// get User Profile Data
    const getUserProfileData = async () => {
        await dispatch(userProfileThunk(dispatch, setUserProfileDataError))
    }
    useEffect(() => {
        if(token) {
            getUserProfileData()
        }
    },[token])
// get User Profile Data

// set user initial data
    useEffect(() => {
        if(userProfileData &&
            userProfileData?.profile &&
            userProfileData?.profile[0] &&
            userProfileData?.profile[0]?.name &&
            userProfileData?.profile[0]?.email) {

            userName.setValue(`${userProfileData?.profile[0]?.name}`)
            email.setValue(`${userProfileData?.profile[0]?.email}`)
        }
    },[userProfileData, userProfileData?.profile,])
// set user initial data

// confirm email and show confirmation message
    const confirmEmail = async (e) => {
        e.preventDefault()

        //clear error
        setConfirmEmailError(false)

        //disable button
        disableEmailConfirmButton.setButtonDisableState(true)
        await dispatch(confirmEmailThunk(dispatch, email.value, setConfirmEmailError))
    }
    useEffect(() => {
        if(!confirmEmailError && userEmailConfirmData.success) {
           setShowEmailConfirmMessage(true)

            // enable button
            disableEmailConfirmButton.setButtonDisableState(false)
        }
    }, [userEmailConfirmData]);
// confirm email and show confirmation message

// update User Profile
    const updateUserProfile = async (e) => {
        e.preventDefault()

        // clear error
        setUserProfileUpdateError(false)

        //disable button
        disableSaveUserProfileButton.setButtonDisableState(true)

        await dispatch(updateUserProfileThunk(dispatch, userName.value, email.value, setUserProfileUpdateError))

        //enable button
        disableSaveUserProfileButton.setButtonDisableState(false)
    }
// end update User Profile

// delete  user profile
    const handleDeleteAccountMessage = () => {
        setDeleteAccountConfirmMessage(prevState => !prevState)
    }

    const sendDeleteAccountRequest = async () => {

        //disable button
        disableDeleteAccountButton.setButtonDisableState(true)

         await dispatch(deleteAccountThunk(dispatch, setDeleteAccountError))

        //enable button
        disableDeleteAccountButton.setButtonDisableState(false)
    }

    useEffect(() => {
        if(deleteAccountData.error === false) {
            setShowDeleteAccountSuccessMessage(true)
        }
    }, [deleteAccountData]);
// end delete user  profile

    return (
        <div className={`userProfile`}>
            {
                noUserProfileDataError && noUserProfileDataError.message &&
                <p className={`userProfile__no-user-data`}>{noUserProfileDataError.message}</p>

            }
            {
                !showEmailConfirmMessage &&
                <form action="" className={`userProfile__form`}>
                    <div className={`userProfile__form-inputs-wrapper`}>
                        <div className={`userProfile__form-item`}>
                            <span className={`userProfile__form-label`}>Imię i nazwisko</span>
                            <InputLocal
                                type="text"
                                value={userName.value}
                                inputClassList={"input-local input-login"}
                                placeholder={'Imię i nazwisko'}
                                onChange={(e) => userName.onChange(e)}
                                onBlur={(e) => userName.onBlur(e)}
                                error={userName.errorMessage}
                            />
                        </div>
                        <div className={`userProfile__form-item user-phone`}>
                            <span className={`userProfile__form-label`}>Numer telefonu</span>
                            <div>
                                <span>
                                    {
                                        userProfileData &&
                                        userProfileData?.profile[0] &&
                                        userProfileData?.profile[0]?.phone &&
                                        userProfileData?.profile[0]?.phone
                                    }
                                </span>
                                <img src={simpleChekMark} alt="check mark"/>
                            </div>
                        </div>
                        <div className={`userProfile__form-item userProfile__form-item-email`}>
                            <span className={`userProfile__form-label`}>Email</span>
                            <InputLocal
                                type="email"
                                value={email.value}
                                inputClassList={"input-local input-login"}
                                placeholder={'Email'}
                                onChange={(e) => email.onChange(e)}
                                onBlur={(e) => email.onBlur(e)}
                                error={email.errorMessage}
                            />
                            <button
                                className={`userProfile__email-confirm-button ${disableEmailConfirmButton.buttonDisableState ? 'confirm-button-disabled' : ''}`}
                                disabled={disableEmailConfirmButton.buttonDisableState}
                                onClick={confirmEmail}
                            >
                                {
                                    disableEmailConfirmButton.buttonDisableState
                                    ?
                                        <LoadingRing/>
                                    :
                                        'Potwierdź'
                                }

                            </button>
                        </div>
                    </div>
                    <div className={`userProfile__form-buttons-wrapper`}>
                        {
                            userProfileUpdateError && userProfileUpdateError?.message &&
                            <span className={`userProfile__form-error`}>
                                {userProfileUpdateError.message}
                            </span>
                        }
                        {
                            confirmEmailError && confirmEmailError?.message &&
                            <span className={`userProfile__form-error`}>
                                {confirmEmailError.message}
                            </span>
                        }
                        {
                            deleteAccountError && deleteAccountError?.message &&
                            <span className={`userProfile__form-error`}>
                                {deleteAccountError.message}
                            </span>
                        }
                        {
                            <ButtonBig
                                buttonText={disableSaveUserProfileButton.buttonDisableState ? <LoadingRing/> : 'Zapisz'}
                                buttonAction={updateUserProfile}
                                isButtonDisabledArray={
                                        [
                                            userName.inputValid,
                                            email.inputValid,
                                            !disableSaveUserProfileButton.buttonDisableState
                                        ]
                                    }
                            />
                        }
                    </div>
                </form>
            }
            {
                showEmailConfirmMessage &&
                <div className={`userProfile__email-confirm-message`}>
                    <div className={`userProfile__email-confirm-message-close`}
                        onClick={() => {
                            setShowEmailConfirmMessage(false)
                            dispatch(setConfirmEmailData(''))
                        }}>
                        <img src={backArrow} alt="back"/>
                    </div>
                    <h2>Potwierdzenie adresu e-mail</h2>
                    <SimpleTextInfo
                        simpleTextInfo={`Wysłaliśmy e-mail na Twój adres e-mail. Aby potwierdzić, przejdź do linku w e-mailu.`}
                        setting={`light`}
                    />
                </div>
            }
            <div className={`userProfile__bottom`}>
                <button onClick={handleDeleteAccountMessage}>
                    Usuń konto
                </button>
                <a href="https://nova-partner.pl/privacy-policy/" target="_blank">
                    Polityka prywatności
                </a>
            </div>
            {
                showDeleteAccountConfirmMessage &&
                <div className={'userProfile__delete-account-confirm-message'}>
                    <div className={`userProfile__delete-account-confirm-message-close`}
                         onClick={handleDeleteAccountMessage}>
                        <img src={closeIcon} alt="back"/>
                    </div>
                    <PageTitle titleText={`Czy na pewno chcesz usunąć konto?`}/>
                    <div className={'userProfile__delete-account-confirm-message-buttons-wrapper'}>
                        <ButtonBig
                            buttonText={`Nie`}
                            buttonAction={handleDeleteAccountMessage}
                            isButtonDisabledArray={[true]}
                        />
                        <ButtonBig
                            buttonText={disableDeleteAccountButton.buttonDisableState ? <LoadingRing/> : `Tak`}
                            buttonAction={sendDeleteAccountRequest}
                            isButtonDisabledArray={[true, !disableDeleteAccountButton.buttonDisableState]}
                        />
                    </div>
                </div>
            }
            {
                showDeleteAccountSuccessMessage &&
                <div className={'userProfile__delete-account-success-message'}>
                    <div className={`userProfile__delete-account-success-message-close`}
                         onClick={() => {
                             setShowDeleteAccountSuccessMessage(false)
                             logOut()
                         }}
                    >
                        <img src={closeIcon} alt="back"/>
                    </div>
                    <img src={successIcon} alt="success"/>
                    <PageTitle titleText={`Twoje konto zostało pomyślnie usunięte`}/>
                </div>
            }
        </div>
    )
}