import {createSlice} from "@reduxjs/toolkit";

const updateUserProfileSlice = createSlice({
    name: "updateUserProfileSlice",
    initialState: {
        updateUserProfileData: '',
    },
    reducers: {
        setUpdateUserProfileData(state, action) {
            state.updateUserProfileData = action?.payload
        },

    }
})

export default updateUserProfileSlice.reducer
export const {setUpdateUserProfileData} = updateUserProfileSlice.actions