import {createRequestHeaders} from "../helpers/createRequestHeaders";
import {createRequestOptions} from "../helpers/createRequestOptions";
import {baseURL} from "../constants/apiConstants/baseUrl";
import {apiErrorInterceptor} from "../interceptors/apiErrorInterceptor";


export async function apiRequestService(apiEndPoint, requestMethod, requestBodyData){

    const requestHeaders = createRequestHeaders()

    const requestOptions = createRequestOptions(
        `${requestMethod}`,
        requestHeaders,
        requestBodyData
    )

    try {
        const response = await fetch(`${baseURL}${apiEndPoint}`, requestOptions)

        if(!response.ok && response.status === 401) {
            throw new Error(response.status)
        }
        else {
            return response
        }
    }
    catch (error) {
        apiErrorInterceptor(error);
    }




}