import {setUploadAndSendFactureData} from "./uploadAndSendFacture.slice";
import {apiErrorInterceptor} from "../../interceptors/apiErrorInterceptor";
import {apiRequestService} from "../../services/apiRequestService";

export const uploadAndSendFactureThunk = (dispatch, factureName, file, setError) => {

    return async (dispatch) => {
        try {

            const apiEndPoint = `/facture-send`

            const requestBody = new FormData();
            requestBody.append("facture", file, "file");
            requestBody.append("name", factureName);

            const response =
                await apiRequestService(apiEndPoint, 'POST', requestBody)

            const data = await response.json();

            if(response.ok) {
                setError(false)
                dispatch(setUploadAndSendFactureData(data));
            }
            else {
                setError(data)
            }
        }
        catch (error) {
            apiErrorInterceptor(error);
        }
    };
};


