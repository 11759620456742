
import './App.css';
import './static/scss/variables.scss'
import "./static/scss/globalStyles.scss"
import {Routes, Route, useNavigate} from "react-router-dom";
import {SignUpPage} from "./pages/SignUpPage/SignUpPage";
import {LoginPage} from "./pages/LoginPage/LoginPage";
import {UserPage} from "./pages/UserPage/UserPage";
import {SuccessMessagePage} from "./pages/SuccessMessagePage/SuccessMessagePage";
import {useAuth} from "./hooks/useAuth";


function App() {

    const {isAuth, token, logOut} = useAuth()
    // const navigate = useNavigate()

    // useEffect(() => {
    //     window.navigateFunc = navigate
    // }, []);

  return (
      <div className="App">
        <div className={`main`}>
          <Routes>
            <Route path="/" element={<LoginPage/>} />
            <Route path="/loginpage" element={<LoginPage/>} />
            <Route path="/signuppage" element={<SignUpPage/>} />
            <Route path="/userpage" element={isAuth ? <UserPage/> : <LoginPage/> } />
            <Route path="/email-confirm-success" element={<SuccessMessagePage/>} />
          </Routes>
        </div>
      </div>
  );
}

export default App;