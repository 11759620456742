import {setConfirmEmailData} from "./confirmEmail.slice";
import {apiErrorInterceptor} from "../../interceptors/apiErrorInterceptor";
import {apiRequestService} from "../../services/apiRequestService";


export const confirmEmailThunk = (dispatch, email, setError) => {

    return async (dispatch) => {
        try {
            const apiEndPoint = '/auth/email-confirm/sent'

            const requestBody = new URLSearchParams();
            requestBody.append("email", `${email}`);

            const response =
                await apiRequestService(apiEndPoint, 'POST', requestBody)

            const data = await response.json();

            if (response.ok) {
                dispatch(setConfirmEmailData(data))
            }
            else {
                setError(data)
            }

        } catch (error) {
            apiErrorInterceptor(error);
        }
    };
};


