import "./UserPage.scss";
import React, {useEffect, useState} from 'react';
import { UserTransactions } from "./components/UserTransactions/UserTransactions";
import { UserNotifications } from "./components/UserNotifications/UserNotifications";
import { UserProfile } from "./components/UserProfile/UserProfile";
import { PageTitle } from "../../components/UiLibraryComponents/PageTitle/PageTitle";
import bellIcon from "../../assets/icons/Bell.svg"
import backArrow from "../../assets/icons/back_arrow.svg"
import {useAuth} from "../../hooks/useAuth";
import {SimpleLink} from "../../components/UiLibraryComponents/SimpleLink/SimpleLink";
import {loginPageLink, userPageLink} from "../../constants/linksConstants/linksConstants";
import {useDispatch, useSelector} from "react-redux";
import {unreadNotificationsThunk} from "../../redux/unreadNotificationsSlice/unreadNotifications.thunk";
import {UserWithdrawals} from "./components/UserWithdrawals/UserWithdrawals";
import {WithdrawalApplication} from "./components/WithdrawalApplication/WithdrawalApplication";
import {WithdrawalsArchive} from "./components/WithdrawalsArchive/WithdrawalsArchive";
import {FacturesList} from "./components/FacturesList/FacturesList";
import {UploadAndSendFacture} from "./components/UploadAndSendFacture/UploadAndSendFacture";
import {FacturesArchive} from "./components/FacturesArchive/FacturesArchive";
import closeIcon from "../../assets/icons/close-icon.svg";
import {ButtonBig} from "../../components/UiLibraryComponents/Buttons/ButtonBig/ButtonBig";
import {userProfileThunk} from "../../redux/userProfileSlice/userProfile.thunk";
import {useNavigate} from "react-router-dom";



export const UserPage = () => {

// states and variables
    const [currentComponent, setCurrentComponent] = useState('UserTransactions');
    const [prevComponent, setPrevComponent] = useState(null);
    const dispatch = useDispatch()
    const unreadNotificationsData = useSelector(state => state.unreadNotificationsSlice.unreadNotificationsData)
    const [showLogoutMessage, setShowLogoutMessage] = useState(false);
    const userProfileData = useSelector(state => state?.userProfileSlice.userProfileData)
    const {isAuth, token, logOut} = useAuth()
    const navigate = useNavigate()

    //error states
    const [noNotificationsDataError, setNotificationsDataError] = useState(false)

// states and variables

// component change
    const handleComponentChange = (componentName) => {
        if (componentName !== currentComponent) {
            setPrevComponent(currentComponent);
            setCurrentComponent(componentName);
        }
    };
// end component change

// return to previous component
    const handleGoBack = () => {
        if (currentComponent === 'WithdrawalApplication' || currentComponent === 'WithdrawalsArchive') {
            setCurrentComponent('UserWithdrawals');
            setPrevComponent('UserTransactions');
        }
        else if (currentComponent === 'UploadAndSendFacture' || currentComponent === 'FacturesArchive') {
            setCurrentComponent('FacturesList');
            setPrevComponent('UserTransactions');
        }
        else {
            setCurrentComponent('UserTransactions');
            setPrevComponent(null);
        }
    };

// end return to previous component

// set page title
    const getPageTitle = () => {
        switch (currentComponent) {
            case 'UserTransactions':
                return 'Transakcje';
            case 'UserNotifications':
                return 'Powiadomienia';
            case 'UserProfile':
                return 'Profil';
            case 'UserWithdrawals':
                return 'Wypłaty';
            case 'WithdrawalApplication':
                return 'Wypłata';
            case 'WithdrawalsArchive':
                return 'Archiwum wypłat'
            case 'FacturesList':
                return 'Faktury'
            case 'UploadAndSendFacture':
                return 'Dodaj fakturę'
            case 'FacturesArchive':
                return 'Archiwum faktur'

            default:
                return '';
        }
    };
// end set page title

// user notifications counter
    const getUnreadNotificationsCount = () => {
        dispatch(unreadNotificationsThunk(dispatch, setNotificationsDataError))
    }
    useEffect(() => {
        if(token) {
            getUnreadNotificationsCount()
        }
    }, [token])
// end user notifications counter

// logout confirm
    const handleLogoutMessage = () => {
        setShowLogoutMessage(prevState => !prevState)
    }
// end logout confirm

// get user data for webPush
    const getUserProfileData = async () => {
        await dispatch(userProfileThunk(dispatch))
    }

    useEffect(() => {
        if(token) {
            getUserProfileData()
        }
    },[token])

    useEffect(() => {
        if(isAuth && userProfileData &&
            userProfileData?.profile &&
            userProfileData?.profile[0] &&
            userProfileData?.profile[0]?.name &&
            userProfileData?.profile[0]?.email) {

            let name = userProfileData?.profile[0]?.name
            let email = userProfileData?.profile[0]?.email
            let wepPushData = {name, email}

            localStorage.setItem('webPushData', JSON.stringify(wepPushData));
        }
    }, [userProfileData]);
// end get user data for webPush


    return (
        <>
            {
                isAuth &&
                <div className={`userPage container ${currentComponent === 'UserNotifications' ? 'dark-bg' : ''}`}>
                    {
                        showLogoutMessage &&
                        <div className={'userPage__logout-confirm-message'}>
                            <div className={`userPage__logout-confirm-message-close`}
                                 onClick={handleLogoutMessage}>
                                <img src={closeIcon} alt="back"/>
                            </div>
                            <PageTitle titleText={`Czy na pewno chcesz wylogować się z konta?`}/>
                            <div className={'userPage__logout-confirm-message-buttons-wrapper'}>
                                <ButtonBig
                                    buttonText={`Nie`}
                                    buttonAction={handleLogoutMessage}
                                    isButtonDisabledArray={[true]}
                                />
                                <ButtonBig
                                    buttonText={`Tak`}
                                    buttonAction={logOut}
                                    isButtonDisabledArray={[true]}
                                />
                            </div>
                        </div>
                    }
                    <div className={`userPage__top`}>
                        {
                            prevComponent &&
                            (currentComponent !== 'UserTransactions') &&
                            <div
                                className={`userPage__back-button`}
                                onClick={handleGoBack}
                            >
                                <img src={backArrow} alt="Back"/>
                            </div>
                        }
                        <PageTitle titleText={getPageTitle()} />
                        <span
                            onClick={() => handleComponentChange('UserNotifications')}
                            className={`userPage__notification-button`}
                        >
                            <img src={bellIcon} alt="Notifications"/>
                            {
                                !noNotificationsDataError &&
                                unreadNotificationsData.unread_notifications &&
                                (parseInt(unreadNotificationsData.unread_notifications) !== 0)
                                ?
                                <span className={`userPage__notification-unread`}>
                                    {unreadNotificationsData.unread_notifications}
                                </span>
                                :
                                ''
                            }
                        </span>
                    </div>

                    {currentComponent === 'UserTransactions' && <UserTransactions />}
                    {currentComponent === 'UserNotifications' && <UserNotifications />}
                    {currentComponent === 'UserProfile' && <UserProfile />}
                    {currentComponent === 'UserWithdrawals' && <UserWithdrawals handleComponentChange={handleComponentChange}/>}
                    {currentComponent === 'WithdrawalApplication' && <WithdrawalApplication handleComponentChange={handleComponentChange}/>}
                    {currentComponent === 'WithdrawalsArchive' && <WithdrawalsArchive />}
                    {currentComponent === 'FacturesList' && <FacturesList handleComponentChange={handleComponentChange}/>}
                    {currentComponent === 'UploadAndSendFacture' && <UploadAndSendFacture/>}
                    {currentComponent === 'FacturesArchive' && <FacturesArchive/>}

                    <ul className={`userPage__nav-panel`}>
                        <li
                            className={`userPage__nav-panel-button ${currentComponent === 'UserProfile' ? 'active' : ''}`}
                            onClick={() => handleComponentChange('UserProfile')}
                        >
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="5" cy="5" r="5" transform="matrix(-1 0 0 1 20 3.75)" stroke="#11A44C" strokeWidth="1.875"/>
                                <path d="M6.25 21.1683C6.25 20.0929 6.92607 19.1335 7.93886 18.7718V18.7718C12.5051 17.1411 17.4949 17.1411 22.0611 18.7718V18.7718C23.0739 19.1335 23.75 20.0929 23.75 21.1683V22.8127C23.75 24.297 22.4354 25.4372 20.966 25.2273L19.773 25.0569C16.6071 24.6046 13.3929 24.6046 10.227 25.0569L9.03401 25.2273C7.56463 25.4372 6.25 24.297 6.25 22.8127V21.1683Z" stroke="#11A44C" strokeWidth="1.875"/>
                            </svg>
                            <span className={`userPage__nav-panel-button-label`}>
                                Profil
                            </span>
                        </li>
                        <li
                            className={`userPage__nav-panel-button ${currentComponent === 'FacturesList' ? 'active' : ''}`}
                            onClick={() => handleComponentChange('FacturesList')}
                        >
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="4.33325" y="2.16675" width="17.3333" height="21.6667" rx="4.33333" stroke="#11A44C" strokeWidth="1.625"/>
                                <path d="M8.6665 7.5835H17.3332" stroke="#11A44C" strokeWidth="1.625" strokeLinecap="round"/>
                                <path d="M8.6665 13H17.3332" stroke="#11A44C" strokeWidth="1.625" strokeLinecap="round"/>
                                <path d="M8.6665 18.4167H12.9998" stroke="#11A44C" strokeWidth="1.625" strokeLinecap="round"/>
                            </svg>
                            <span className={`userPage__nav-panel-button-label`}>
                                Faktury
                            </span>
                        </li>
                        <li
                            className={`userPage__nav-panel-button ${currentComponent === 'UserTransactions' ? 'active' : ''}`}
                            onClick={() => handleComponentChange('UserTransactions')}
                        >
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.125 13.673C3.125 12.1428 3.82572 10.6968 5.02678 9.7486L11.9018 4.32097C13.7183 2.88685 16.2817 2.88685 18.0982 4.32096L24.9732 9.74859C26.1743 10.6968 26.875 12.1428 26.875 13.673V21.875C26.875 24.6364 24.6364 26.875 21.875 26.875H20C19.3096 26.875 18.75 26.3154 18.75 25.625V21.875C18.75 20.4943 17.6307 19.375 16.25 19.375H13.75C12.3693 19.375 11.25 20.4943 11.25 21.875V25.625C11.25 26.3154 10.6904 26.875 10 26.875H8.125C5.36358 26.875 3.125 24.6364 3.125 21.875L3.125 13.673Z" stroke="#11A44C" strokeWidth="1.875"/>
                            </svg>
                            <span className={`userPage__nav-panel-button-label`}>
                                Transakcje
                            </span>
                        </li>
                        <li
                            className={`userPage__nav-panel-button ${currentComponent === 'UserWithdrawals' ? 'active' : ''}`}
                            onClick={() => handleComponentChange('UserWithdrawals')}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                <rect x="2.16669" y="6.5" width="21.6667" height="17.3333" rx="5.41667" stroke="#11A44C" strokeWidth="1.625"/>
                                <path d="M20.5834 7.04175V7.04175C20.5834 4.52508 18.2735 2.64231 15.8085 3.14981L6.49107 5.0681C3.97335 5.58645 2.16669 7.80297 2.16669 10.3735L2.16669 14.0834" stroke="#11A44C" strokeWidth="1.625"/>
                                <path d="M6.5 18.9584H13" stroke="#11A44C" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16.25 15.1668C16.25 13.6711 17.4626 12.4585 18.9583 12.4585H23.8333V17.8752H18.9583C17.4626 17.8752 16.25 16.6626 16.25 15.1668V15.1668Z" stroke="#11A44C" strokeWidth="1.625"/>
                                <path d="M18.9584 15.1667H19.175" stroke="#11A44C" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <span className={`userPage__nav-panel-button-label`}>
                                Wypłaty
                            </span>
                        </li>
                        <li className={`userPage__nav-panel-button`}
                            onClick={handleLogoutMessage}
                        >
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 20V22.5C20 25.2614 17.7614 27.5 15 27.5H8.75C5.98858 27.5 3.75 25.2614 3.75 22.5V7.5C3.75 4.73858 5.98858 2.5 8.75 2.5H15C17.7614 2.5 20 4.73858 20 7.5V10" stroke="#11A44C" strokeWidth="1.875" strokeLinecap="round"/>
                                <path d="M23.75 18.75L26.6161 15.8839C27.1043 15.3957 27.1043 14.6043 26.6161 14.1161L23.75 11.25" stroke="#11A44C" strokeWidth="1.875" strokeLinecap="round"/>
                                <path d="M26.25 15L11.25 15" stroke="#11A44C" strokeWidth="1.875" strokeLinecap="round"/>
                            </svg>
                            <span className={`userPage__nav-panel-button-label`}>
                                Wyjście
                            </span>
                        </li>
                    </ul>
                </div>
            }
            {
                !isAuth &&
                <SimpleLink
                    navigatePath={loginPageLink}
                    linkText={"Zaloguj się"}
                />
            }
        </>
    );
};
