import "./UploadAndSendFacture.scss"
import {InputLocal} from "../../../../components/UiLibraryComponents/Inputs/InputLocal/InputLocal";
import {ButtonBig} from "../../../../components/UiLibraryComponents/Buttons/ButtonBig/ButtonBig";
import {useAuth} from "../../../../hooks/useAuth";
import {useDispatch, useSelector} from "react-redux";
import {useInput} from "../../../../hooks/useInput";
import {useEffect, useRef, useState} from "react";
import {uploadAndSendFactureThunk} from "../../../../redux/uploadAndSendFactureSlice/uploadAndSendFacture.thunk";
import closeIcon from "../../../../assets/icons/close-icon.svg";
import successIcon from "../../../../assets/icons/Successmark.svg";
import {PageTitle} from "../../../../components/UiLibraryComponents/PageTitle/PageTitle";
import {setUploadAndSendFactureData} from "../../../../redux/uploadAndSendFactureSlice/uploadAndSendFacture.slice";
import {useDisableButton} from "../../../../hooks/useDisableButton";
import {LoadingRing} from "../../../../components/UiLibraryComponents/LoadingRing/LoadingRing";


export const UploadAndSendFacture = () => {
// states and variables
    const {token} = useAuth()
    const dispatch = useDispatch()
    const [selectedFileName, setSelectedFileName] = useState("");
    const factureName = useInput('', {isEmpty: true, minLength: 3, maxLength: 40})
    const [file, setFile] = useState(false);
    const [showFactureConfirmMessage, setShowFactureConfirmMessage] = useState(false)
    const disableSendFactureButton = useDisableButton()
    const uploadAndSendFactureData = useSelector(state => state.uploadAndSendFactureSlice.uploadAndSendFactureData)
    const fileInput = useRef(null)

    //error state
    const [fileProcessingError, setFileProcessingError] = useState(false);
    const [factureNameMissingError, setFactureNameMissingError] = useState(false);
    const [fileTypeError, setFileTypeError] = useState(false);
// end states and variables

// facture file upload
    const handleFileUpload = (e) => {
        const selectedFile = e.target.files[0];

        setFile(selectedFile);

        setSelectedFileName(selectedFile?.name);

        setFileProcessingError(false)

        // file type check
        if (selectedFile) {
            const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];

            if (allowedTypes.includes(selectedFile.type)) {
                setFileTypeError(false)
                setFile(selectedFile);
                setSelectedFileName(selectedFile.name || '');
            }
            else {
                setFactureNameMissingError(false)
                setFileTypeError(true)
                setFile(null);
                setSelectedFileName('');
            }
        }
    };
// end facture file upload

// send facture
    const sendFacture = async (e) => {
        e.preventDefault()

        //disable button
        disableSendFactureButton.setButtonDisableState(true)

        await dispatch(uploadAndSendFactureThunk(dispatch, factureName.value, file, setFileProcessingError))

        //enable button
        disableSendFactureButton.setButtonDisableState(false)

        //clear facture name input
        factureName.setValue('')

        //clear file
        setFile(false)

        //clear file input current value
        fileInput.current.value = ""

        //clear file name
        setSelectedFileName('')
    };
// end send facture

// show success message
    useEffect(() => {
        if(!fileProcessingError &&
            !uploadAndSendFactureData.error &&
            uploadAndSendFactureData.success) {
            setShowFactureConfirmMessage(true)
        }
    }, [uploadAndSendFactureData]);
// end show success message

// set facture name missing error
    useEffect(() => {
        if(file && selectedFileName && !factureName.value) {
            setFactureNameMissingError(true)
        }
        if(file && selectedFileName && factureName.value) {
            setFactureNameMissingError(false)
        }
    }, [factureName.value, file, selectedFileName]);
// end set facture name missing error


    return (
        <div className={`uploadFacture`}>
            {
                showFactureConfirmMessage &&
                <div className={'uploadFacture__confirm-message'}>
                    <div className={`uploadFacture__confirm-message-close`}
                         onClick={() => {
                             setShowFactureConfirmMessage(false)
                             dispatch(setUploadAndSendFactureData(''))
                         }}>
                        <img src={closeIcon} alt="back"/>
                    </div>
                    <img src={successIcon} alt="success"/>
                    <PageTitle titleText={`Faktura została przesłana pomyślnie`}/>
                </div>
            }
            <form action="" className={`uploadFacture__form`}>
                <div className={`uploadFacture__form-inputs-wrapper`}>
                    <div
                        className={`uploadFacture__form-item ${factureNameMissingError ? 'facture-warning' : ''}`}
                    >
                        <span className={`uploadFacture__form-label`}>Nazwa faktury</span>
                        <InputLocal
                            type="text"
                            value={factureName.value}
                            inputClassList={"input-local input-login"}
                            placeholder={'Wpisz nazwę faktury'}
                            onChange={(e) => factureName.onChange(e)}
                            onBlur={(e) => factureName.onBlur(e)}
                            error={factureName.errorMessage}
                        />
                        <div className={`uploadFacture__api-error-message-wrapper`}>
                            {factureNameMissingError && <span>Należy wpisać nazwę faktury</span>}
                            {fileTypeError && <span>Plik musi być w jednym z formatów: jpg, jpeg, png</span>}
                            {
                                fileProcessingError && fileProcessingError.message &&
                                <span>{fileProcessingError.message}</span>
                                // <span>Przetwarzanie pliku nie powiodło się</span>
                            }
                        </div>
                    </div>
                </div>
                <div className={`uploadFacture__form-buttons-wrapper`}>
                    <label className={`uploadFacture__form-upload-button`}>
                        <input
                            ref={fileInput}
                            type="file"
                            accept="image/jpeg, image/jpg, image/png"
                            onChange={(e) => handleFileUpload(e)}
                        />
                        {selectedFileName ? `Plik: ${selectedFileName}` : "Wybierz plik"}
                    </label>
                    {
                        <ButtonBig
                            type={`button`}
                            buttonText={disableSendFactureButton.buttonDisableState ? <LoadingRing/> : 'Zapisz'}
                            buttonAction={sendFacture}
                            isButtonDisabledArray={
                                [
                                    factureName.inputValid,
                                    file,
                                    !disableSendFactureButton.buttonDisableState,
                                    !fileTypeError
                                ]
                            }
                        />
                    }
                </div>
            </form>
        </div>
    )
}