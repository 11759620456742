import {setUnreadNotificationsData} from "./unreadNotifications.slice";
import {apiErrorInterceptor} from "../../interceptors/apiErrorInterceptor";
import {apiRequestService} from "../../services/apiRequestService";

export const unreadNotificationsThunk = (dispatch, setError) => {

    return async (dispatch) => {
        try {
            const apiEndPoint = `/notifications/unread-count`

            const response =
                await apiRequestService(apiEndPoint, 'GET')

            const data = await response.json();

            if (response.ok) {
                dispatch(setUnreadNotificationsData(data))
            }
            else {
                setError(data)
            }

        } catch (error) {
            apiErrorInterceptor(error);
        }
    };
};


