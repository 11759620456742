
import {loginPageLink} from "../constants/linksConstants/linksConstants";


export function apiErrorInterceptor(error) {

    const errorCode = parseInt(error.message);
    if (!isNaN(errorCode) && errorCode === 401) {
        localStorage.removeItem('token')
        window.location.href = `${loginPageLink}`
    }
    else {
        console.log("Error:", error);
    }
}

