import {createSlice} from "@reduxjs/toolkit";

const deleteAccountSlice = createSlice({
    name: "deleteAccountSlice",
    initialState: {
        deleteAccountData: '',
    },
    reducers: {
        setDeleteAccountData(state, action) {
            state.deleteAccountData = action?.payload
        },

    }
})

export default deleteAccountSlice.reducer
export const {setDeleteAccountData} = deleteAccountSlice.actions
