import {createSlice} from "@reduxjs/toolkit";

const userNotificationsSlice = createSlice({
    name: "userNotificationsSlice",
    initialState: {
        userNotificationsData: '',
    },
    reducers: {
        setUserNotificationsData(state, action) {
            state.userNotificationsData = action?.payload
        },

    }
})

export default userNotificationsSlice.reducer
export const {setUserNotificationsData} = userNotificationsSlice.actions
