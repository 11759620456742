

import {useRef, useState} from "react";
import "./InputLocal.scss"

export const InputLocal = ({type, value, inputClassList, placeholder, inputIcon, error, onChange, onBlur}) => {
    const [inputFocus, setInputFocus] = useState(false);

    const inputFocusChange = (boolean) => {
        setInputFocus(boolean)
    }

    const handleInputChange = (event) => {
        const inputValue = event.target.value;

        if (type === "tel" && !/^\+?\d*$/.test(inputValue)) {
            return;
        }

        //set phone number to input state
        onChange(event);
    };

    return (
        <div
            className={`input-component ${inputFocus ? "input-component-active" : "" }`}
            onFocus={() => {inputFocusChange(true)}}
            onBlur={() => {inputFocusChange(false)}}
        >
            {
                type !== 'tel' &&
                <input
                    className={`input-component__input-field ${inputClassList} ${error ? 'input-error': ''}`}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            }
            {
                type === 'tel' &&
                <input
                    className={`input-component__input-field ${inputClassList} ${error ? 'input-error': ''}`}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleInputChange}
                    onBlur={onBlur}
                    pattern={type === "tel" ? "[0-9+]*" : undefined}
                />
            }
            {error && <span className={`input-component__error-message`}>{error}</span>}
        </div>
    )
}

