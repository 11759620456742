import "./FacturesList.scss"
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../../../../hooks/useAuth";
import {ButtonBig} from "../../../../components/UiLibraryComponents/Buttons/ButtonBig/ButtonBig";
import {useEffect, useState} from "react";
import {facturesThunk} from "../../../../redux/facturesSlice/factures.thunk";
import whiteFacture from "../../../../assets/icons/white-fakture.svg"
import smallFactureIcon from "../../../../assets/icons/small-facture-icon.png"
import LoadingSkeletonList from "../../../../components/UiLibraryComponents/LoadingSkeletonList/LoadingSkeletonList";



export const FacturesList = ({handleComponentChange}) => {
// states and variables
    const facturesData = useSelector(state => state.facturesSlice.facturesData.data)
    const dispatch = useDispatch()
    const {token} = useAuth()

    //error states
    const [noFacturesDataError, setFacturesDataError] = useState(false)

// end states and variables

// get Factures Data
    const getFacturesData = async () => {
        await dispatch(facturesThunk(dispatch, setFacturesDataError))
    }

    useEffect(() => {
        if(token) {
            getFacturesData()
        }
    },[token])
// end get Factures Data

    return (
        <div className={`facturesList`}>
            <div className={`facturesList__main-balance`}>
                <div className={`facturesList__main-balance-inner`}>
                    <span>Twoje zarobki</span>
                    <p>3234,07 zł</p>
                </div>
                {/*<img src={moneyIcon} alt=""/>*/}
                <div className={`facturesList__facture-icon-wrapper`}>
                    <img src={whiteFacture} alt="icon"/>
                </div>
            </div>
            <div className={`facturesList__application`}>
                <ButtonBig
                    buttonText={`Dodaj fakturę`}
                    buttonAction={() => handleComponentChange('UploadAndSendFacture')}
                    isButtonDisabledArray={[true]}
                />
            </div>
            <div className="facturesList__list-wrapper">
                <div className="facturesList__list-title-wrapper">
                    <h3 className="facturesList__list-title">Przesłane faktury</h3>
                    <span onClick={() => handleComponentChange('FacturesArchive')}>Archiwum</span>
                </div>
                {
                    !noFacturesDataError && !noFacturesDataError.message &&
                    !facturesData &&
                    <LoadingSkeletonList/>
                }
                {
                    noFacturesDataError && noFacturesDataError.message &&
                    <p className={`facturesList__no-data`}>{noFacturesDataError.message}</p>
                }
                <ul className="facturesList__list">
                    {
                        facturesData &&
                        facturesData.map((item, index) => {

                            // const formattedDate = new Date(item.upload_date).toLocaleDateString('ru-RU').split('.').join('.');
                            const uniqueKey = `factures-${index}`;

                            return (
                                <li
                                    className="facturesList__list-item"
                                    key={uniqueKey}
                                >
                                    <div className="facturesList__list-item-left">
                                        <img src={smallFactureIcon} alt="icon"/>
                                        <div className="facturesList__list-item-left-text">
                                            <p>
                                                {item.name}
                                            </p>
                                            <span>
                                                {item.upload_date}
                                            </span>
                                        </div>
                                    </div>
                                    <p
                                        className={`facturesList__list-item-status ${item?.status ? item.status : ''}`}
                                    >
                                        {item.status === "sent" &&  'Wysłane'}
                                        {item.status === "paid" &&  'Wypłacone'}
                                        {item.status === "approve" &&  'Przyjęte'}
                                        {item.status === "rejected" &&  'Odrzucone'}
                                    </p>
                                </li>
                            )
                        })
                    }
                 </ul>
            </div>
        </div>
    )
}
