import {setLoginData} from "../../redux/loginSlice/login.slice";
import {apiRequestService} from "../../services/apiRequestService";


export const loginThunk = (dispatch, userPhone, setError) => {

    return async (dispatch) => {
        try {
            const apiEndPoint = `/auth/login`

            const requestBody = new URLSearchParams();
            requestBody.append("phone", `${userPhone}`);

            const response =
                await apiRequestService(apiEndPoint, 'POST', requestBody)

            const data = await response.json();

            if (response.ok) {
                dispatch(setLoginData(data))
            }
            else {
                setError(data)
            }
        } catch (error) {
            console.log('Error', error);
        }
    };
};


