import {setWithdrawalApplicationData} from "./withdrawalApplication.slice";
import {apiErrorInterceptor} from "../../interceptors/apiErrorInterceptor";
import {apiRequestService} from "../../services/apiRequestService";

export const withdrawalApplicationThunk = (dispatch, amount, commission, setError) => {

    return async (dispatch) => {
        try {
            const apiEndPoint = `/withdrawal/create`

            const requestBody = new URLSearchParams();
            requestBody.append("withdrawal_amount", `${amount}`);
            requestBody.append("withdrawal_fee", `${commission}`);

            const response =
                await apiRequestService(apiEndPoint, 'POST', requestBody)

            const data = await response.json();

            if(response.ok) {
                setError(false)
                dispatch(setWithdrawalApplicationData(data))
            }
            else {
                setError(data)
            }

        } catch (error) {
            apiErrorInterceptor(error);
        }
    };
};


