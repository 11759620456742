import {createSlice} from "@reduxjs/toolkit";

const facturesArchiveSlice = createSlice({
    name: "facturesArchiveSlice",
    initialState: {
        facturesArchiveData: '',
    },
    reducers: {
        setFacturesArchiveData(state, action) {
            state.facturesArchiveData = action?.payload
        },

    }
})

export default facturesArchiveSlice.reducer
export const {setFacturesArchiveData} = facturesArchiveSlice.actions
