import {createSlice} from "@reduxjs/toolkit";

const loginSlice = createSlice({
    name: "loginSlice",
    initialState: {
        loginData: '',
    },
    reducers: {
        setLoginData(state, action) {
            // state.userDataTemp.userNameTemp = action?.payload?.signUpData
            state.loginData = action?.payload
        },

    }
})

export default loginSlice.reducer
export const {setLoginData} = loginSlice.actions
