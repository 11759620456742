import moneyTransfer from "../../../../assets/icons/money-bill-transfer-solid.svg";
import "./WithdrawalsArchive.scss"
import {useAuth} from "../../../../hooks/useAuth";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {withdrawalsArchiveThunk} from "../../../../redux/withdrawalsArchiveSlice/withdrawalsArchive.thunk";
import LoadingSkeletonList from "../../../../components/UiLibraryComponents/LoadingSkeletonList/LoadingSkeletonList";


export const WithdrawalsArchive = () => {

// states and variables
    const withdrawalsArchiveData = useSelector(state => state?.withdrawalsArchiveSlice?.withdrawalsArchiveData?.data)
    const dispatch = useDispatch()
    const {token} = useAuth()

    //error states
    const [noWithdrawalsArchiveDataError, setWithdrawalsArchiveDataError] = useState(false)

// end states and variables

// get Withdrawals Archive Data
    const getWithdrawalsArchiveData = async () => {
        await dispatch(withdrawalsArchiveThunk(dispatch, 1, setWithdrawalsArchiveDataError))
    }

    useEffect(() => {
        if(token) {
            getWithdrawalsArchiveData()
        }
    },[token])
// end get Withdrawals Archive Data

    return (
        <div className={`withdrawalsArchive`}>
            <div className="withdrawalsArchive__archive-list-wrapper">
                <div className="withdrawalsArchive__archive-list-title-wrapper">
                    <h3 className="withdrawalsArchive__archive-list-title">Zlecenia wypłat</h3>
                </div>
                {
                    !noWithdrawalsArchiveDataError && !noWithdrawalsArchiveDataError.message &&
                    !withdrawalsArchiveData &&
                    <LoadingSkeletonList/>
                }
                {
                    noWithdrawalsArchiveDataError && noWithdrawalsArchiveDataError.message &&
                    <p className={`withdrawalsArchive__no-data`}>{noWithdrawalsArchiveDataError.message}</p>

                }
                <ul className="withdrawalsArchive__archive-list">
                    {
                        withdrawalsArchiveData &&
                        withdrawalsArchiveData.map((item, index) => {

                            // const formattedDate = new Date(item.last_date_withdrawal_update).toLocaleDateString('ru-RU').split('.').join('.');
                            const uniqueKey = `transaction-${index}`;

                            return (
                                <li key={uniqueKey} className="withdrawalsArchive__archive-list-item">
                                    <div className="withdrawalsArchive__archive-list-item-left">
                                        {/*<img src={moneyTransfer} alt="icon"/>*/}
                                        <div className="withdrawalsArchive__transaction-list-item-icon-wrapper">
                                            <img src={moneyTransfer} alt="icon"/>
                                        </div>
                                        <div className="withdrawalsArchive__archive-list-item-left-text">
                                            <p>{item?.withdrawal_amount} zł</p>
                                            <span>{item.last_date_withdrawal_update}</span>
                                        </div>
                                    </div>
                                    <p className="withdrawalsArchive__archive-list-item-amount">
                                        {item.status === "Sent" &&  'Wysłano'}
                                        {item.status === "Paid" &&  'Wypłacone'}
                                    </p>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}