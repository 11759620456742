import "./ButtonBig.scss"
import {useEffect, useState} from "react";

export const ButtonBig = ({buttonText, buttonAction, isButtonDisabledArray}) => {



    const [isButtonActive, setIsButtonActive] = useState(false);

    useEffect(() => {
        if(isButtonDisabledArray &&
            !isButtonDisabledArray.includes(false) &&
            !isButtonDisabledArray.includes('')) {
            setIsButtonActive(true)
        }
        else {
            setIsButtonActive(false)
        }
    },[isButtonDisabledArray])


    return (
        <button
            className={`button-big ${isButtonActive ? "active" : "not-active"}`}
            onClick={(e) => buttonAction(e)}
            disabled={!isButtonActive}
        >
            <span>{buttonText}</span>
        </button>
    )
}
