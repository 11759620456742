import {createSlice} from "@reduxjs/toolkit";

const userProfileSlice = createSlice({
    name: "userProfileSlice",
    initialState: {
        userProfileData: '',
    },
    reducers: {
        setUserProfileData(state, action) {
            state.userProfileData = action?.payload
        },

    }
})

export default userProfileSlice.reducer
export const {setUserProfileData} = userProfileSlice.actions
