import {createSlice} from "@reduxjs/toolkit";

const uploadAndSendFactureSlice = createSlice({
    name: "uploadAndSendFactureSlice",
    initialState: {
        uploadAndSendFactureData: '',
    },
    reducers: {
        setUploadAndSendFactureData(state, action) {
            state.uploadAndSendFactureData = action?.payload
        },
    }
})

export default uploadAndSendFactureSlice.reducer
export const {setUploadAndSendFactureData} = uploadAndSendFactureSlice.actions