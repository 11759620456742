import "./WithdrawalApplication.scss"
import {InputLocal} from "../../../../components/UiLibraryComponents/Inputs/InputLocal/InputLocal";
import {ButtonBig} from "../../../../components/UiLibraryComponents/Buttons/ButtonBig/ButtonBig";
import {useInput} from "../../../../hooks/useInput";
import {useEffect, useState} from "react";
import infoSquareIcon from "../../../../assets/icons/info-square.svg"
import closeIcon from "../../../../assets/icons/close-icon.svg"
import {useDispatch, useSelector} from "react-redux";
import {withdrawalApplicationThunk} from "../../../../redux/withdrawalApplicationSlice/withdrawalApplication.thunk";
import {useAuth} from "../../../../hooks/useAuth";
import {setWithdrawalApplicationData} from "../../../../redux/withdrawalApplicationSlice/withdrawalApplication.slice";
import successIcon from "../../../../assets/icons/Successmark.svg"
import {PageTitle} from "../../../../components/UiLibraryComponents/PageTitle/PageTitle";
import {commissionThunk} from "../../../../redux/commissionSlice/commission.thunk";
import {useDisableButton} from "../../../../hooks/useDisableButton";
import {LoadingRing} from "../../../../components/UiLibraryComponents/LoadingRing/LoadingRing";


export const WithdrawalApplication = () => {

// states and variables
    const withdrawalAmount  = useInput('', {isEmpty: true, minLength: 2, maxLength: 7})
    const haveWithdrawalThisWeek = useSelector(state => state.commissionSlice.commissionData.have_withdrawal_this_week)
    const withdrawalApplicationData = useSelector(state => state.withdrawalApplicationSlice.withdrawalApplicationData)
    const [showWithdrawalInfo, setShowWithdrawalInfo] = useState(false)
    const [showWithdrawalConfirmMessage, setShowWithdrawalConfirmMessage] = useState(false)
    const dispatch = useDispatch()
    const {token} = useAuth()
    const [commission, setCommission] = useState(0)
    const disableSendWithdrawalApplicationButton = useDisableButton()

    // error state
    // const withdrawalApplicationError = useSelector(state => state.errorMessageSlice.withdrawalApplicationError)
    const [withdrawalApplicationErrorState, setWithdrawalApplicationErrorState] = useState(false)
    const [commissionError, setCommissionError] = useState(false)
// end states and variables

// Show Withdrawal Info
    const handleShowWithdrawalInfo = () => {
        setShowWithdrawalInfo(prevState => !prevState)
    }
    const handleInfoBackgroundClick = (event) => {
        if (event.currentTarget === event.target) {
            setShowWithdrawalInfo(prevState => !prevState)
        }
    }
// end Show Withdrawal Info

// get commission
    const getCommission = async () => {
        await dispatch(commissionThunk(dispatch, setCommissionError))
    }

    useEffect(() => {
        if(token) {
            getCommission()
        }
    }, [token]);

    useEffect(() => {
        if(haveWithdrawalThisWeek) {
            setCommission(20)
        }
        if(!haveWithdrawalThisWeek) {
            setCommission(60)
        }
    }, [haveWithdrawalThisWeek]);
// end get commission

// send Withdrawal Application
    const sendWithdrawalApplication = async (e) => {
        e.preventDefault()

        // clear error
        setWithdrawalApplicationErrorState(false)

        // disable button
        disableSendWithdrawalApplicationButton.setButtonDisableState(true)

        await dispatch(withdrawalApplicationThunk(dispatch, withdrawalAmount?.value, commission, setWithdrawalApplicationErrorState))

        // enable button
        disableSendWithdrawalApplicationButton.setButtonDisableState(false)

        // clear input
        withdrawalAmount.setValue('')
    }
//end  send Withdrawal Application

// show  Withdrawal Confirm Message
    useEffect(() => {
        if(!withdrawalApplicationErrorState && withdrawalApplicationData.success) {
            setShowWithdrawalConfirmMessage(true)
        }
    }, [withdrawalApplicationData])
// end show  Withdrawal Confirm Message


    return (
        <div className={'withdrawalApplication'}>
            {
                showWithdrawalConfirmMessage &&
                <div className={'withdrawalApplication__confirm-message'}>
                    <div className={`withdrawalApplication__confirm-message-close`}
                         onClick={() => {
                             setShowWithdrawalConfirmMessage(false)
                             dispatch(setWithdrawalApplicationData(''))
                         }}
                    >
                        <img src={closeIcon} alt="back"/>
                    </div>
                    <img src={successIcon} alt="success"/>
                    <PageTitle titleText={`Twoje zlecenie wypłaty zostało pomyślnie utworzone`}/>
                </div>
            }
            <form action="" className={`withdrawalApplication__form`}>
                <div className={`withdrawalApplication__form-inputs-wrapper`}>
                    <div className={`withdrawalApplication__form-item`}>
                        {
                            showWithdrawalInfo &&
                            <div
                                className={`withdrawalApplication__info-background`}
                                onClick={handleInfoBackgroundClick}
                            >
                                <div className={`withdrawalApplication__info-wrapper`}>
                                    <div className={`withdrawalApplication__info-top`}>
                                        <h3 className={`withdrawalApplication__info-title`}>Finibus et</h3>
                                        <span
                                            className={`withdrawalApplication__info-close`}
                                            onClick={handleShowWithdrawalInfo}
                                        >
                                            <img src={closeIcon} alt="close"/>
                                        </span>
                                    </div>
                                    <div className={`withdrawalApplication__info-text`}>
                                       <b>Wypłata Natychmiastowa</b><br/>
                                        Masz możliwość skorzystać z wypłaty natychmiastowej od wtorku do niedzieli.<br/>
                                        Aby skorzystać z wypłaty musisz mieć powyżej <b>100 zł</b> na koncie.<br/>
                                        Prowizja za każdą wypłatę Natychmiastową - <b>30 zł</b>.<br/>
                                        Przy pierwszej w tygodniu wypłacie natychmiastowej jest też pobierana prowizja tygodniowa - <b>60 zł</b><br/>
                                        Tygodniowa prowizja jest pobierana tylko raz i przy rozliczeniu w najbliższy poniedziałek już nie zapłacisz jej.<br/>
                                        <br/>
                                        Przykład: <br/>
                                        200 na koncie - 60 - 30 = 111 zł do wypłaty<br/>
                                        Przy kolejnej wypłacie w tygodniu:<br/>
                                        200 na koncie - 30 = 170 zł do wypłaty<br/>
                                        Przy wypłacie w poniedziałek już nie płacisz żadnej prowizji<br/>
                                        Natychmiastowe przelewy wysyłane od razu i będziesz miał środki na koncie w ciągu 1-3 god.<br/>
                                    </div>
                                </div>
                            </div>
                        }
                        <span className={`withdrawalApplication__form-label`}>
                            <span>Wprowadź kwotę</span>
                            <span>
                                Prowizja: {commission} zł
                            </span>
                            {
                                commissionError && commissionError.message &&
                                <span>{commissionError.message}</span>
                            }
                        </span>
                        <span
                            className={`withdrawalApplication__form-show-info`}
                            onClick={handleShowWithdrawalInfo}
                        >
                            <img src={infoSquareIcon} alt="info"/>
                        </span>
                        <InputLocal
                            type="number"
                            value={withdrawalAmount && withdrawalAmount?.value && parseFloat(withdrawalAmount?.value).toFixed(0)}
                            inputClassList={"input-local input-login"}
                            placeholder={'100.00'}
                            onChange={(e) => withdrawalAmount.onChange(e)}
                            onBlur={(e) => withdrawalAmount.onBlur(e)}
                            // error={userName.isDirty ? userName.errorMessage : ""}
                            error={withdrawalAmount.errorMessage}
                        />
                        {
                            (withdrawalApplicationErrorState && withdrawalApplicationErrorState.error === true) &&
                            <span  className={`withdrawalApplication__form-error`}>
                                {withdrawalApplicationErrorState.message}
                            </span>
                        }
                    </div>
                </div>
                <div className={`withdrawalApplication__form-buttons-wrapper`}>
                    {<ButtonBig
                        buttonText={disableSendWithdrawalApplicationButton.buttonDisableState ? <LoadingRing/> : 'Wyślij żądanie wypłaty'}
                        buttonAction={sendWithdrawalApplication}
                        isButtonDisabledArray={
                                [
                                    withdrawalAmount.inputValid,
                                    !disableSendWithdrawalApplicationButton.buttonDisableState
                                ]
                            }/>
                    }
                </div>
            </form>
        </div>
    )
}
