import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
// import {setUserDataToLocal} from "../redux/signInSlice/signIn.slice";
import {useNavigate} from "react-router-dom";
// import {synapse} from "../constants/linksConstants/linksConstants";
import {setPhoneConfirmLoginData} from "../redux/phoneConfirmSlice/phoneConfirm.slice";
import {setUserTransactionsData} from "../redux/userTransactions/userTransactions.slice";

export const useAuth = () => {

    const [isAuth, setIsAuth] = useState(false);
    const dispatch = useDispatch()
    const phoneConfirmLoginData =
        useSelector(state => state.phoneConfirmSlice.phoneConfirmLoginData)
    let tokenRef = useRef(null)
    const navigate = useNavigate()

    useEffect(() => {
        if(phoneConfirmLoginData.token) {
            localStorage.setItem("token", JSON.stringify({token: phoneConfirmLoginData.token}));
            setIsAuth(true)
        }
        if(!phoneConfirmLoginData.token) {
            let tokenFromLocalStorage = JSON.parse(localStorage.getItem("token"))

            if(tokenFromLocalStorage) {
                tokenRef.current = tokenFromLocalStorage
                setIsAuth(true)
            }
        }
    }, [phoneConfirmLoginData])

    const logOut = () => {
        dispatch(setPhoneConfirmLoginData(''))
        dispatch(setUserTransactionsData(''))
        localStorage.removeItem("token")
        setIsAuth(false)
        // document.location.reload()
        navigate(`/`)
    }

   return {
        isAuth: isAuth,
        token: phoneConfirmLoginData?.token || tokenRef?.current?.token,
        logOut
    };
}

