import "./PhoneConfirm.scss"
import React, {useState, useRef, useEffect} from 'react';
import {phoneConfirmThunk} from "../../redux/phoneConfirmSlice/phoneConfirm.thunk";
import {useDispatch, useSelector} from "react-redux";
import {ButtonBig} from "../UiLibraryComponents/Buttons/ButtonBig/ButtonBig";
import {SimpleTextInfo} from "../UiLibraryComponents/SimpleTextInfo/SimpleTextInfo";
import {PageTitle} from "../UiLibraryComponents/PageTitle/PageTitle";
import {useDisableButton} from "../../hooks/useDisableButton";
import {LoadingRing} from "../UiLibraryComponents/LoadingRing/LoadingRing";
import {normalizePhoneNumber} from "../../helpers/normalizePhoneNumber";

export const PhoneConfirm = ({userPhone, type, stepBack, redirect,}) => {

// states and variables
    const [inputs, setInputs] = useState(['', '', '', '']);
    const inputRefs = useRef([0, 1, 2, 3].map(() => React.createRef()));
    const dispatch = useDispatch()
    const [repeatRequest, setRepeatRequest] = useState(false)
    const [timer, setTimer] = useState(25);
    const loginData = useSelector(state => state.loginSlice.loginData)
    const phoneConfirmLoginData = useSelector(state => state.phoneConfirmSlice.phoneConfirmLoginData)
    const disablePhoneConfirmButton = useDisableButton()

    //error state
    const [phoneConfirmError, setPhoneConfirmError] = useState(false)
// end states and variables

    const handleInputChange = (index, value) => {

        if (/^\d$/.test(value)) {
            const newInputs = [...inputs];
            newInputs[index] = value;
            setInputs(newInputs);

            if (index < 3 && value.length === 1 && inputs[index + 1] === '') {

                inputRefs.current[index + 1].current.focus();
            }
        }
        else if (value === '') {
            const newInputs = [...inputs];
            newInputs[index] = '';
            setInputs(newInputs);
        }
    };

    // const handleInputClick = (index) => {
    //     if (index < 3 && inputs[index] === '') {
    //         inputRefs.current[index].current.focus();
    //     }
    // };

    const handleClickPhoneConfirm = async (e) => {
        e.preventDefault()

        //disable button
        disablePhoneConfirmButton.setButtonDisableState(true)

        let phoneCode = inputs.join('')

        // Remove '+' symbol from phone number if present
        const phoneNumber = normalizePhoneNumber(userPhone)

        await dispatch(phoneConfirmThunk(dispatch, phoneNumber, phoneCode, type, setPhoneConfirmError))

        //enable button
        disablePhoneConfirmButton.setButtonDisableState(false)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (timer > 0) {
                setTimer(timer - 1);
            } else {
                setRepeatRequest(true);
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [timer]);

    const handleClickRepeatRequest = (e) => {
        e.preventDefault()

        setRepeatRequest(prevState => !prevState)
        setTimer(25)
    }

    useEffect(() => {
        if (phoneConfirmLoginData.token && phoneConfirmLoginData.success && type === 'login') {
            redirect()
        }
    }, [loginData, phoneConfirmLoginData])


    return (
        <div className={`phoneConfirm-form`}>
            <div onClick={stepBack}
                 className={`phoneConfirm__back-button`}
            >
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.1576 2.3669C13.0656 2.27474 12.9564 2.20163 12.8362 2.15174C12.716 2.10185 12.5871 2.07617 12.4569 2.07617C12.3268 2.07617 12.1979 2.10185 12.0777 2.15174C11.9574 2.20163 11.8482 2.27474 11.7563 2.3669L5.17756 8.94565C5.10417 9.01889 5.04595 9.10588 5.00622 9.20165C4.96649 9.29743 4.94604 9.40009 4.94604 9.50377C4.94604 9.60746 4.96649 9.71012 5.00622 9.80589C5.04595 9.90166 5.10417 9.98866 5.17756 10.0619L11.7563 16.6406C12.1442 17.0286 12.7696 17.0286 13.1576 16.6406C13.5455 16.2527 13.5455 15.6273 13.1576 15.2394L7.4259 9.49982L13.1655 3.76023C13.5455 3.38023 13.5455 2.7469 13.1576 2.3669Z" fill="#1E232C" stroke="#1E232C" strokeWidth="0.2"/>
                </svg>
            </div>
            <form>
                <div className={`phoneConfirm-form__top`}>
                    <PageTitle titleText={'Potwierdzenie numeru telefonu'}/>
                    <SimpleTextInfo
                        simpleTextInfo={'Wprowadź kod autoryzacji, który został wysłany na numer telefonu'}
                    />
                </div>
                <div className={`phoneConfirm-form__inputs-wrapper`}>
                    {inputs.map((value, index) => (
                        <input
                            className={`phoneConfirm-form__input ${value ? 'has-data' : ''}`}
                            key={index}
                            name={`code-input-${index}`}
                            type="number"
                            value={value}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            // onClick={() => handleInputClick(index)}
                            ref={inputRefs.current[index]}
                        />
                    ))}
                </div>
                <div className={`phoneConfirm-form__button-wrapper`}>
                    {
                        phoneConfirmError && phoneConfirmError.message &&
                        <span className={`phoneConfirm-form__error`}>{phoneConfirmError.message}</span>
                    }
                    {
                        <ButtonBig
                            buttonText={disablePhoneConfirmButton.buttonDisableState ? <LoadingRing/> : 'Potwierdź'}
                            buttonAction={handleClickPhoneConfirm}
                            isButtonDisabledArray={
                                [inputs[0], inputs[1], inputs[2], inputs[3], !disablePhoneConfirmButton.buttonDisableState]}/>
                    }
                </div>
                {
                    !repeatRequest &&
                    <div  className={`phoneConfirm-form__resend-code`}>
                        Wyślij kod ponownie {Math.floor(timer / 60)}:{(timer % 60).toString().padStart(2, '0')}
                    </div>
                }
                {
                    repeatRequest &&
                    <div
                        className={`phoneConfirm-form__resend-code`}
                        onClick={handleClickRepeatRequest}
                    >
                        Wyślij kod ponownie!
                    </div>
                }
            </form>
        </div>
    );
};

