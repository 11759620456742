import {createSlice} from "@reduxjs/toolkit";

const signUpSlice = createSlice({
    name: "signUpSlice",
    initialState: {
        signUpData: '',
    },
    reducers: {
        setNewUserData(state, action) {
            state.signUpData = action?.payload
        },

    }
})

export default signUpSlice.reducer
export const {setNewUserData} = signUpSlice.actions
