import "./SimpleTextInfo.scss"

export const SimpleTextInfo = ({simpleTextInfo, setting}) => {

     return (
        <span className={`simple-text-info ${setting === 'light' ? 'light-color' : ''}`}>
           {simpleTextInfo}
        </span>
    )
}
