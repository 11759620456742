import {apiRequestService} from "../../services/apiRequestService";
import {setConfirmEmailByLinkData} from "./confirmEmailByLink.slice";
import {apiErrorInterceptor} from "../../interceptors/apiErrorInterceptor";
export const confirmEmailByLinkThunk = (dispatch, email, setError) => {

     return async (dispatch) => {
        try {
            const apiEndPoint = `/auth/email-confirm`

            const requestBody = new URLSearchParams();
            requestBody.append("email", `${email}`);

            const response =
                await apiRequestService(apiEndPoint, 'POST', requestBody)

            const data = await response.json();

            if (response.ok) {
                console.log('confirmEmailByLinkThunk data', data)
                dispatch(setConfirmEmailByLinkData(data))
            }
            else {
                setError(data)
            }

        } catch (error) {
            apiErrorInterceptor(error, dispatch);
        }
    };
};


