import {createSlice} from "@reduxjs/toolkit";


const userTransactionsSlice = createSlice({
    name: "userTransactionsSlice",
    initialState: {
        userTransactionsData: '',
    },
    reducers: {
        setUserTransactionsData(state, action) {
            state.userTransactionsData = action?.payload
        },
    }
})

export default userTransactionsSlice.reducer
export const {setUserTransactionsData} = userTransactionsSlice.actions
