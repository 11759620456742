import {createSlice} from "@reduxjs/toolkit";

const userWithdrawalsDataSlice = createSlice({
    name: "userWithdrawalsDataSlice",
    initialState: {
        userWithdrawalsData: '',
    },
    reducers: {
        setUserWithdrawalsData(state, action) {
            state.userWithdrawalsData = action?.payload
        },

    }
})

export default userWithdrawalsDataSlice.reducer
export const {setUserWithdrawalsData} = userWithdrawalsDataSlice.actions
