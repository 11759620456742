import {combineReducers, configureStore } from "@reduxjs/toolkit";

import signUpSlice from "./signUpSlice/signUp.slice";
import phoneConfirmSlice from "./phoneConfirmSlice/phoneConfirm.slice";
import loginSlice from "./loginSlice/login.slice";
import userTransactionsSlice from "./userTransactions/userTransactions.slice";
import userProfileSlice from "./userProfileSlice/userProfile.slice";
import confirmEmailSlice from "./confirmEmailSlice/confirmEmail.slice";
import updateUserProfileSlice from "./updateUserProfileSlice/updateUserProfile.slice";
import userNotificationsSlice from "./userNotificationsSlice/userNotifications.slice";
import unreadNotificationsSlice from "./unreadNotificationsSlice/unreadNotifications.slice";
import withdrawalApplicationSlice from "./withdrawalApplicationSlice/withdrawalApplication.slice"
import userWithdrawalsDataSlice from "./userWithdrawalsDataSlice/userWithdrawalsData.slice"
import withdrawalsArchiveSlice from "./withdrawalsArchiveSlice/withdrawalsArchive.slice"
import facturesSlice from "./facturesSlice/factures.slice"
import uploadAndSendFactureSlice from "./uploadAndSendFactureSlice/uploadAndSendFacture.slice"
import facturesArchiveSlice from "./facturesArchiveSlice/facturesArchive.slice"
import commissionSlice from "./commissionSlice/commission.slice"
import deleteAccountSlice from "./deleteAccountSlice/deleteAccount.slice"
import confirmEmailByLinkSlice from "./confirmEmailByLink/confirmEmailByLink.slice"
// import localUserDataSlice from "./localUserDataSlice/localUserData.slice";


const rootReducer = combineReducers(
    {
        signUpSlice: signUpSlice,
        phoneConfirmSlice: phoneConfirmSlice,
        loginSlice: loginSlice,
        userTransactionsSlice: userTransactionsSlice,
        userProfileSlice: userProfileSlice,
        confirmEmailSlice: confirmEmailSlice,
        updateUserProfileSlice: updateUserProfileSlice,
        userNotificationsSlice: userNotificationsSlice,
        unreadNotificationsSlice: unreadNotificationsSlice,
        withdrawalApplicationSlice: withdrawalApplicationSlice,
        userWithdrawalsDataSlice: userWithdrawalsDataSlice,
        withdrawalsArchiveSlice: withdrawalsArchiveSlice,
        facturesSlice: facturesSlice,
        uploadAndSendFactureSlice: uploadAndSendFactureSlice,
        facturesArchiveSlice: facturesArchiveSlice,
        commissionSlice: commissionSlice,
        deleteAccountSlice: deleteAccountSlice,
        confirmEmailByLinkSlice: confirmEmailByLinkSlice,
    }
)

export const store = configureStore({
    reducer: rootReducer,
})
