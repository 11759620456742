
export function createRequestHeaders() {

    let tokenFromLocalStorage = JSON.parse(localStorage.getItem("token"))

    const requestHeaders = new Headers();
    requestHeaders.append("Accept", "application/json");

    if(tokenFromLocalStorage) {
        requestHeaders.append("Authorization", `Bearer ${tokenFromLocalStorage.token}`);
    }

    return requestHeaders
}