import "./UserNotifications.scss"
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {userNotificationsThunk} from "../../../../redux/userNotificationsSlice/userNotifications.thunk";
import logoFav from "../../../../assets/logos/logo-fav.png"
import {useAuth} from "../../../../hooks/useAuth";
import LoadingSkeletonList from "../../../../components/UiLibraryComponents/LoadingSkeletonList/LoadingSkeletonList";

export const UserNotifications = () => {
// states and variables
    const dispatch = useDispatch()
    const userNotificationsData = useSelector(state => state?.userNotificationsSlice.userNotificationsData)
    const {notifications} = userNotificationsData

    const {token} = useAuth()

    //error states
    const [noUserNotificationsDataError, setUserNotificationsDataError] = useState(false)

// states and variables

// get User Notifications Data
    const getUserNotificationsData = async () => {
        await dispatch(userNotificationsThunk(dispatch, setUserNotificationsDataError))
    }
    useEffect(() => {
        if(token) {
            getUserNotificationsData()
        }

    },[token])
// get User Notifications Data

// calc time ago
    const getTimeAgo = (dateString) => {
        const notificationDate = new Date(dateString);
        const currentTime = new Date();
        const difference = currentTime - notificationDate;

        const minutes = Math.floor(difference / (1000 * 60));
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days >= 1) {
            return `${days}d ago`;
        } else if (hours >= 1) {
            const remainingMinutes = minutes % 60;
            return `${hours}h ${remainingMinutes}m ago`;
        } else if (minutes < 1) {
            return `just now`
        }
        else {
            return `${minutes}m ago`;
        }
    };
// calc time ago

    return (
        <div className={`userNotifications`}>
            {
                !noUserNotificationsDataError && !noUserNotificationsDataError.message &&
                !notifications &&
                <LoadingSkeletonList/>
            }
            {
                noUserNotificationsDataError && noUserNotificationsDataError.message &&
                <p className={`userNotifications__no-data`}>{noUserNotificationsDataError.message}</p>

            }
            <ul className={`userNotifications__notifications-list`}>
                {
                    notifications &&
                    notifications.map((item, index) =>  {
                        return (
                            <li key={item.date} className={`userNotifications__notifications-list-item`}>
                                <div className={`userNotifications__notifications-list-item-top`}>
                                    <h3>
                                        <img src={logoFav} alt="logo"/>
                                        <span>
                                            {item.title}
                                        </span>
                                    </h3>
                                    <span className={`userNotifications__notifications-list-item-time`}>
                                        {getTimeAgo(item.date)}
                                    </span>
                                </div>
                                <p className={`userNotifications__notifications-list-item-text`}>
                                    {item.message}
                                </p>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}